import React from "react";

import styles from "./styles.module.scss";

import Avatar from "@/components/shared/Avatar/Avatar";
import CalculatedValue from "@/components/shared/CalculatedValue/CalculatedValue";
import { ReactComponent as MasterCardIcon } from "@assets/icons/master-card.svg";
import { ITransactionListItem } from "@/store/transactions/transactions.types";
import { parseDate } from "@/utils/helpers/parseDate";
import AvatarIconSwitcher from "@/components/transactions/AvatarIconSwitcher/AvatarIconSwitcher";
import { getTransactionTitle } from "@/utils/helpers/transactions";

export default function ModalDisputeCard({
  transactionData,
}: {
  transactionData: ITransactionListItem;
}) {
  return (
    <div className={styles.container}>
      <Avatar mode="square" type="danger">
        <AvatarIconSwitcher
          state={transactionData.state}
          disputes={transactionData.disputes}
          short_type={transactionData.short_type}
        />
      </Avatar>
      <div className={styles.content}>
        <div className={styles.top}>
          <span className={styles.title}>
            {getTransactionTitle(transactionData)}
          </span>
          <CalculatedValue
            value={`₽ ${transactionData.amount}`}
            type="danger"
            size="sm"
            mode="money"
          />
        </div>
        <div className={styles.bottom}>
          <span className={styles.time}>
            {parseDate(transactionData.created_at, "default", true)}
          </span>
          <span className={styles.card}>
            <MasterCardIcon /> {transactionData.payer_card_number}
          </span>
        </div>
      </div>
    </div>
  );
}
