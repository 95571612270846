import React from "react";

import styles from "./styles.module.scss";
import sharedStyles from "@utils/styles/sharedStyles.module.scss";

import {
  Chart as ChartJS,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  scales,
  CoreChartOptions,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { graphResponse } from "@/utils/constants/graphResponse";
import {
  abbreviateNumber,
  createLineChart,
  getMaxChartY,
} from "@/utils/helpers/chart";
import DropDown from "@/components/controls/DropDown/DropDown";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { parseDate } from "@/utils/helpers/parseDate";

ChartJS.register(
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  scales
);

let cursym = "₽";
let ffamily = "'Inter', sans-serif";

export const options: CoreChartOptions<"line"> = {
  layout: {
    padding: {
      top: 35,
    },
  },
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    title: {
      display: false,
    },
    legend: {
      display: false,
    },
    tooltip: {
      intersect: false,
      mode: "index" as "index",
      backgroundColor: "#ffffff",
      position: "nearest",
      displayColors: false,
      caretSize: 0,
      caretPadding: 10,
      borderColor: "#EEF2F9",
      borderWidth: 1,
      padding: {
        top: 12,
        right: 40,
        bottom: 12,
        left: 12,
      },
      cornerRadius: 8,

      titleColor: "#2787F5",
      titleMarginBottom: 0,
      titleFont: {
        family: ffamily,
        weight: 600,
        size: 16,
      },

      footerColor: "#899FB8",
      footerFont: {
        family: ffamily,
        weight: 600,
        size: 12,
      },

      bodyFont: {
        size: 0,
      },

      callbacks: {
        title: (context: any) => {
          const { formattedValue } = context[0];
          return `${cursym} ${formattedValue}`;
        },
        afterTitle: (context: any) => {
          const { label } = context[0];
          return parseDate(label);
        },
        footer: (context: any) => {
          const { formattedValue } = context[1];
          return `${cursym} ${formattedValue}`;
        },
        afterFooter: (context: any) => {
          const { label } = context[1];
          // "years" , "months", "weeks" , "days"
          // return moment(label).locale("ru").subtract(1, "days").calendar();
          return parseDate(label);
          // .format("MMMM Do YYYY, h:mm")
        },
      },
    },
  },
  scales: {
    x: {
      display: true,
      min: 0,
      max: graphResponse.current.length,
      grid: { display: false, drawBorder: false },
      border: { color: "#F3F5F8" },
      ticks: {
        type: "time",
        color: "#123E70",
        font: {
          family: ffamily,
          weight: 400,
        },
        callback: (val: number) => {
          const date = graphResponse.current[val].date;
          // return moment(date).format("HH:mm");
          return parseDate(date, "timeOnly");
        },
      },
      // display: false,
      // min: 0,
      // max: graphResponse.current.length,
    },
    y: {
      max: Math.ceil(
        getMaxChartY(graphResponse.current, graphResponse.preview, 1.25)
      ),
      min: 0,
      display: true,
      border: {
        display: false,
        dash: [5, 3],
      },
      grid: {
        color: "#F3F5F8",
        lineWidth: 1,
        drawOnChartArea: true,
        drawTicks: false,
        circular: true,
        offset: true,
      },
      ticks: {
        color: "#123E70",
        font: {
          family: ffamily,
          weight: 500,
        },
        callback: (val: number) => {
          return abbreviateNumber(val);
        },
      },
      // max: getMaxChartY(graphResponse.current, graphResponse.preview),
      // min: -50,
      // display: false,
    },
  },
  elements: {
    point: {
      radius: 0,
      borderWidth: 1,
      hoverRadius: 6,
    },
  },
  // @ts-ignore
  hover: {
    mode: "index" as "index",
    intersect: false,
  },
};

export const data = {
  labels: graphResponse.current.map((el) => el.date),
  datasets: [
    createLineChart(graphResponse.current, "#2787F5"),
    createLineChart(graphResponse.preview, " #91EECD"),
  ],
};

export function HorizontalChart() {
  const { t } = useTranslation();

  return (
    <div className={classNames(sharedStyles.cardContainer, styles.container)}>
      <div className={styles.heading}>
        <h3 className={styles.title}>{t("mainPage.stats.chart.title")}</h3>
        <span className={styles.dropDown}>
          <DropDown
            items={[
              t("mainPage.stats.chart.dd.day"),
              t("mainPage.stats.chart.dd.month"),
              t("mainPage.stats.chart.dd.year"),
            ]}
          />
        </span>
      </div>
      <div className={styles.canvas}>
        <Line options={options} data={data} />
      </div>
    </div>
  );
}
