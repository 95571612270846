import React from "react";

import { SizeType, StyleType } from "@/utils/types/shared";
import classNames from "classnames";

import styles from "./styles.module.scss";

interface ICalculatedValue {
  value: string;
  type?: StyleType;
  mode?: "money" | "default";
  moneyPercentage?: string;
  moneyTransparent?: boolean;
  moneyLeft?: string;
  size?: SizeType;
}

export default function CalculatedValue(props: ICalculatedValue) {
  const {
    value,
    mode = "default",
    type,
    size,
    moneyPercentage,
    moneyTransparent,
    moneyLeft,
  } = props;

  if (mode === "default") {
    return (
      <p
        className={classNames(styles.value, {
          [styles.accent]: type === "accent",
          [styles.primary]: type === "primary",
          [styles.success]: type === "success",
          [styles.danger]: type === "danger",
          [styles.warning]: type === "warning",
          [styles.successLight]: type === "successLight",

          [styles.lg]: size === "lg",
          [styles.sm]: size === "sm",
        })}
      >
        {value}
      </p>
    );
  }
  const [integer, fraction] = value.split(".");
  return (
    <p
      className={classNames(styles.value, {
        [styles.accent]: type === "accent",
        [styles.primary]: type === "primary",
        [styles.success]: type === "success",
        [styles.danger]: type === "danger",
        [styles.warning]: type === "warning",
        [styles.successLight]: type === "successLight",

        [styles.lg]: size === "lg",
        [styles.sm]: size === "sm",
      })}
    >
      {moneyLeft && <span className={styles.fraction}>{moneyLeft}&nbsp;</span>}
      <span
        className={classNames({
          [styles.moneyTransparent]: Boolean(moneyTransparent),
        })}
      >
        {integer}
      </span>
      {fraction && (
        <span className={styles.fraction}>
          ,{fraction?.length === 1 ? "0" + fraction : fraction}
        </span>
      )}
      {moneyPercentage && (
        <span className={styles.moneyPercentage}>{moneyPercentage}</span>
      )}
    </p>
  );
}
