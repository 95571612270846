import { ITransactionListItem } from "@/store/transactions/transactions.types";
import React from "react";

import { ReactComponent as CancelIcon } from "@assets/icons/cancel.svg";
import { ReactComponent as ClockIcon } from "@assets/icons/clock-sm.svg";
export default function useAvatarBadgeSwitcher() {
  function avatarBadgeSwitcher(
    data: Pick<ITransactionListItem, "short_type" | "state" | "disputes">
  ) {
    const { state, short_type, disputes } = data;

    // if (short_type === "c2c") {
    //   return null;
    // }
    // if (state === "failed") {
    //   return <ClockIcon />;
    // }
    // if (state === "canceled") {
    //   return <CancelIcon />;
    // }
    if (disputes.length === 2) {
      return "x2";
    }
    return null;
  }
  return { avatarBadgeSwitcher };
}
