import React from "react";

import styles from "./styles.module.scss";
import sharedStyles from "@utils/styles/sharedStyles.module.scss";

import { IStyledComponent } from "@/utils/types/shared";
import classNames from "classnames";

export default function Text(props: IStyledComponent) {
  const { children, type } = props;
  return (
    <>
      <span
        className={classNames(styles.value, {
          [sharedStyles.accent]: type === "accent",
          [sharedStyles.primary]: type === "primary",
          [sharedStyles.success]: type === "success",
          [sharedStyles.danger]: type === "danger",
          [sharedStyles.warning]: type === "warning",
          [sharedStyles.successLight]: type === "successLight",
          [sharedStyles.muted]: type === "muted",
        })}
      >
        {children}
      </span>
    </>
  );
}
