import React, { useState, useRef, useMemo } from "react";

import styles from "./styles.module.scss";

import { useGetUserInfoQuery } from "@/store/user/user.api";
import { useTranslation } from "react-i18next";
import { ReactComponent as MoonIcon } from "@assets/icons/moon.svg";
import { ReactComponent as GlobeIcon } from "@assets/icons/globe.svg";
import classNames from "classnames";
import HeaderDd from "./HeaderDd/HeaderDd";
import DropDown from "@/components/controls/DropDown/DropDown";
import HeaderDdLoader from "./HeaderDd/HeaderDdLoader/HeaderDdLoader";

export default function Header() {
  const [isLangActive, setIsLangActive] = useState(false);

  const { t, i18n } = useTranslation();

  const { data, isLoading } = useGetUserInfoQuery();

  function languageChange(value: string) {
    value === "Русский (RU)" && i18n.changeLanguage("ru");
    // value === "Английский (EN)" && i18n.changeLanguage("en");
  }

  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <div className={styles.controls}>
          <button className={styles.theme}>
            <MoonIcon />
          </button>

          <div
            className={classNames(styles.lang, {
              [styles.active]: isLangActive,
            })}
          >
            <GlobeIcon />
            <DropDown
              mode="heightFull"
              items={[
                "Русский (RU)",
                // "Английский (EN)"
              ]}
              onActiveChange={setIsLangActive}
              onChange={languageChange}
            />
          </div>
        </div>
        {isLoading ? <HeaderDdLoader /> : <HeaderDd data={data} />}
      </header>
    </div>
  );
}
