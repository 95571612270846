import React from "react";

import styles from "./styles.module.scss";

import { NavLink } from "react-router-dom";
import classNames from "classnames";
import CalculatedValue from "@/components/shared/CalculatedValue/CalculatedValue";
import SectionDdItem from "../SectionDdItem/SectionDdItem";
import Chip from "@/components/shared/Chip/Chip";

export interface ISubRoute {
  route: string;
  name: string;
}
export interface IRoute {
  icon: React.ReactElement;
  route: string | ISubRoute[];
  name: string;
  chipValue?: string;
}

interface ICurrency {
  amount: string;
  currency: string;
  account: string;
}

interface ISection {
  type?: "navigation" | "currencies";
  title: string;
  routes?: IRoute[];
  currencies?: ICurrency[];
}

export default function Section(props: ISection) {
  const { type = "navigation", title, routes, currencies } = props;
  if (type === "currencies") {
    return (
      <div>
        <h5 className={styles.title}>{title}</h5>
        <ul>
          {currencies?.map(({ amount, currency, account }) => (
            <li key={currency} className={classNames(styles.currencyItem)}>
              <div className={styles.item}>
                <span className={classNames(styles.currency)}>{currency}</span>
                <CalculatedValue value={amount} size="sm" mode="money" />
              </div>
              <div className={classNames(styles.item, styles.account)}>
                {account}
              </div>
            </li>
          ))}
        </ul>
      </div>
    );
  }
  return (
    <nav>
      <h5 className={styles.title}>{title}</h5>
      <ul>
        {routes?.map(({ route, icon, name, chipValue }) => (
          <li key={name} className={styles.item}>
            {Array.isArray(route) ? (
              <SectionDdItem key={name} route={route} icon={icon} name={name} />
            ) : (
              <NavLink
                to={route}
                className={({ isActive }) =>
                  classNames(styles.link, isActive && styles.active)
                }
              >
                <div className={styles.icon}>{icon}</div>
                <span>
                  {name}{" "}
                  {chipValue && (
                    <span className={styles.chip}>
                      <Chip type="accent">{chipValue}</Chip>
                    </span>
                  )}
                </span>
              </NavLink>
            )}
          </li>
        ))}
      </ul>
    </nav>
  );
}
