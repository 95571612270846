import React from "react";

import styles from "./styles.module.scss";
import sharedStyles from "@utils/styles/sharedStyles.module.scss";

import classNames from "classnames";
import { IStyledComponent, SizeType } from "@/utils/types/shared";
import Loader from "../../shared/Loader/Loader";

interface IButton extends IStyledComponent {
  disabled?: boolean;
  loading?: boolean;
  onClick?(): void;
  size?: SizeType;
}

export default function Button(props: IButton) {
  const {
    children,
    disabled = false,
    loading,
    onClick,
    type = "accent",
    size,
  } = props;

  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={classNames(styles.button, {
        [styles.disabled]: disabled,

        [sharedStyles.accent]: type === "accent",
        [sharedStyles.primary]: type === "primary",
        [sharedStyles.success]: type === "success",
        [sharedStyles.danger]: type === "danger",
        [sharedStyles.warning]: type === "warning",
        [sharedStyles.successLight]: type === "successLight",
        [styles.muted]: type === "muted",

        [styles.sm]: size === "sm",
        [styles.lg]: size === "lg",

        [styles.loading]: loading,
      })}
    >
      <>{loading ? <Loader /> : children}</>
    </button>
  );
}
