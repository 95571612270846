import React, { useTransition } from "react";

import styles from "./styles.module.scss";
import sharedStyles from "@utils/styles/sharedStyles.module.scss";

import ModalShare from "../ModalShare/ModalShare";
import Popover from "../../../shared/Popover/Popover";
import CalculatedValue from "@/components/shared/CalculatedValue/CalculatedValue";
import { ReactComponent as CheckIcon } from "@assets/icons/receipt-check.svg";
import { ReactComponent as AlertIcon } from "@assets/icons/alert-triangle.svg";
import { StyleType } from "@/utils/types/shared";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { ITransactionListItem } from "@/store/transactions/transactions.types";

interface IModalIntro {
  type: StyleType;
  title: string;
  titleRight?: string;
  subTitle: string;
  transactionData: ITransactionListItem;
  setCreateDispute(state: boolean): void;
  showShare?: boolean;
  isDisputeAvailable?: boolean;
  popover?: string;
}

export default function ModalIntro(props: IModalIntro) {
  const {
    type,
    transactionData,
    subTitle,
    setCreateDispute,
    title,
    titleRight,
    showShare,
    isDisputeAvailable,
    popover,
  } = props;

  const { t } = useTranslation();

  return (
    <>
      <div className={styles.title}>
        <Popover title={title} count={titleRight}>
          {popover}
        </Popover>
      </div>
      <p className={styles.subTitle}>{subTitle}</p>
      <div className={styles.amount}>
        <CalculatedValue
          value={`₽ ${transactionData.amount}`}
          mode="money"
          type={type}
        />
      </div>
      <div className={styles.amountInfo}>
        <span className={styles.amountInUSDT}>
          {transactionData.amount_usdt} USDT
        </span>
        <span className={styles.commission}>
          <CalculatedValue
            value={`₽ ${transactionData.comission}`}
            moneyPercentage={transactionData.comission_percent + " %"}
            mode="money"
            type={type}
            size="sm"
          />
        </span>
      </div>

      {showShare && <ModalShare />}

      {isDisputeAvailable && (
        <button
          onClick={() => setCreateDispute(true)}
          className={classNames(styles.control, {
            [sharedStyles.primary]: type === "primary",
            [sharedStyles.accent]: type === "accent",
            [sharedStyles.success]: type === "success",
            [sharedStyles.danger]: type === "danger",
            [sharedStyles.warning]: type === "warning",
            [sharedStyles.successLight]: type === "successLight",

            [sharedStyles.disabled]: transactionData.disputes?.length === 2,
          })}
          disabled={transactionData.disputes?.length === 2}
        >
          <span className={styles.controlContent}>
            {/* <CheckIcon className={styles.controlIcon} /> Чек по транзакции */}
            <>
              <AlertIcon width={18} height={18} />
              {transactionData.disputes?.length === 0 && (
                <span>{t("modals.transaction.buttons.dispute.0")}</span>
              )}
              {transactionData.disputes?.length === 1 && (
                <span>{t("modals.transaction.buttons.dispute.1")}</span>
              )}
              {transactionData.disputes?.length === 2 && (
                <span>{t("modals.transaction.buttons.dispute.2")}</span>
              )}
            </>
          </span>
        </button>
      )}
    </>
  );
}
