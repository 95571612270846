import React, { useState, useRef } from "react";

import styles from "./styles.module.scss";

import { IWrapComponent } from "@/utils/types/shared";
import { ReactComponent as Chevron } from "@assets/icons/chevron-down.svg";
import classNames from "classnames";
import useOnClickOutside from "@/utils/hooks/useClickOutside";
import Button from "@/components/controls/Button/Button";
import { useTranslation } from "react-i18next";
import useMountTransition from "@/utils/hooks/useMountTransition";

interface IFilterBase extends IWrapComponent {
  title?: string;
  titlePlaceholder: string;
  controlsType?: "apply";
  onSubmit?: () => void;
  onCancel?: () => void;
}

export default function FilterBase(props: IFilterBase) {
  const {
    title,
    children,
    controlsType,
    titlePlaceholder,
    onSubmit,
    onCancel,
  } = props;

  const [isActive, setActive] = useState(false);

  const containerRef = useRef<null | HTMLDivElement>(null);
  const { t } = useTranslation();
  const hasTransitionedIn = useMountTransition(isActive, 300);
  useOnClickOutside(containerRef, close);

  function cancel() {
    close();
    onCancel && onCancel();
  }
  function close() {
    setActive(false);
  }
  function toggle() {
    setActive((isActive) => !isActive);
  }
  function submit() {
    onSubmit && onSubmit();
    setActive(false);
  }
  return (
    <div ref={containerRef} className={styles.container}>
      <button onClick={toggle} className={styles.button}>
        {title ? (
          <span className={styles.title}>{title}</span>
        ) : (
          <span className={classNames(styles.title, styles.titlePlaceholder)}>
            {titlePlaceholder}
          </span>
        )}
        <Chevron
          className={classNames(styles.icon, { [styles.active]: isActive })}
        />
      </button>
      {(hasTransitionedIn || isActive) && (
        <div
          className={classNames(styles.content, {
            [styles.in]: hasTransitionedIn,
            [styles.active]: isActive,
          })}
        >
          <div className={styles.fields}>{children}</div>

          {controlsType === "apply" ? (
            <Button size="sm" onClick={submit}>
              {t("transactionsPage.filterControls.submit")}
            </Button>
          ) : (
            <div className={styles.controls}>
              <Button size="sm" onClick={submit}>
                {t("transactionsPage.filterControls.submit")}
              </Button>

              <Button onClick={cancel} size="sm" type="muted">
                {t("transactionsPage.filterControls.cancel")}
              </Button>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
