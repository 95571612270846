import React, { useEffect, useRef } from "react";

import styles from "./styles.module.scss";

import useOnClickOutside from "@/utils/hooks/useClickOutside";
import ReactModal from "react-modal";
import { useNavigate } from "react-router-dom";

interface IModalBase {
  children: React.ReactNode;
  style?: React.CSSProperties | undefined;
}

export default function ModalBase(props: IModalBase) {
  const { children, style = {} } = props;

  const contentRef = useRef<HTMLDivElement | null>(null);
  const navigate = useNavigate();

  function closeModal() {
    navigate(-1);
  }

  useOnClickOutside(contentRef, closeModal);

  useEffect(() => {
    document.body.classList.add("hideScroll");
    return () => document.body.classList.remove("hideScroll");
  }, []);

  return (
    <ReactModal
      appElement={document.body}
      isOpen={true}
      style={{
        overlay: {
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          width: "100%",
          zIndex: 1000,
          backgroundColor: "rgba(0, 0, 0, .5)",
          overflow: "auto",
        },
        content: {
          position: "static",
          maxWidth: "480px",
          margin: "60px auto",
          left: 0,
          top: 0,
          border: 0,
          padding: 0,
          borderRadius: "32px",
          overflow: "visible",
          ...style,
        },
      }}
    >
      <div className={styles.content} ref={contentRef}>
        {children}
      </div>
    </ReactModal>
  );
}
