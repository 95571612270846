import React, { useRef, useState, useEffect } from "react";

import styles from "./styles.module.scss";

import { ReactComponent as BackIcon } from "@assets/icons/flip-backward.svg";
import { ITransactionListItem } from "@/store/transactions/transactions.types";
import { useCreateDisputeMutation } from "@/store/disputes/disputes.api";
import { useTranslation } from "react-i18next";
import Button from "@/components/controls/Button/Button";
import ModalDisputeCard from "../ModalDisputeCard/ModalDisputeCard";
import Uploader from "./Uploader/Uploader";
import Input from "@/components/forms/Input/Input";
import ModalIntroSwitcher from "../../ModalBase/ModalIntroSwitcher/ModalIntroSwitcher";
import ModalTitle from "../../ModalBase/ModalTitle/ModalTitle";

interface IModalDispute {
  setCreateDispute(state: boolean): void;
  transactionData: ITransactionListItem;
  setIsDisputeCreated(val: boolean): void;
}

export default function ModalDispute(props: IModalDispute) {
  const { setCreateDispute, transactionData, setIsDisputeCreated } = props;
  const [comment, setComment] = useState<string | null>("");
  const [files, setFiles] = useState<any[]>([]);
  const { t } = useTranslation();
  const [
    createDisputeMutation,
    { isLoading, data, error, isError, isSuccess },
  ] = useCreateDisputeMutation();
  function onSubmit() {
    createDisputeMutation({
      comment: comment,
      amount: transactionData.amount,
      proofs: files,
      payment_transaction_id: transactionData.id,
    });
  }
  useEffect(() => {
    setIsDisputeCreated(isSuccess);
  }, [isSuccess]);

  if (isSuccess) {
    return (
      <ModalIntroSwitcher
        type="warning"
        transactionData={transactionData}
        setCreateDispute={setCreateDispute}
        isSuccessDispute={isSuccess}
      />
    );
  }
  return (
    <>
      {/* <h5 className={styles.title}>
        {t("modals.disputeCreate.title")}
        {transactionData.disputes.length === 2 ? (
          <span className={styles.count}> x2</span>
        ) : (
          ""
        )}
      </h5> */}
      <ModalTitle>{t("modals.disputeCreate.title")}</ModalTitle>
      <p className={styles.subTitle}>{transactionData.id}</p>
      <ModalDisputeCard transactionData={transactionData} />
      <div className={styles.block}>
        <h6 className={styles.blockTitle}>
          {t("modals.disputeCreate.file.title")}
          <span className={styles.danger}>*</span>
        </h6>
        <p className={styles.blockSubTitle}>
          {t("modals.disputeCreate.file.subTitle")}
        </p>
        <Uploader files={files} setFiles={setFiles} />
      </div>
      <div className={styles.block}>
        <h6 className={styles.blockTitle}>
          {t("modals.disputeCreate.comment.title")}
        </h6>
        <p className={styles.blockSubTitle}>
          {t("modals.disputeCreate.comment.subTitle")}
        </p>
        <div className={styles.inputWrapper}>
          <Input
            inputAttributes={{
              placeholder: t("modals.disputeCreate.comment.placeHolder"),
              value: comment,
              onChange: (e: any) => setComment(e.target.value),
            }}
          />
        </div>
      </div>
      <div className={styles.submit}>
        <Button
          onClick={onSubmit}
          type="warning"
          disabled={files.length < 1}
          loading={isLoading}
        >
          Подать спор
        </Button>
      </div>
      {isError && (
        <p className={styles.error}>
          {/* @ts-ignore */}
          {error?.data?.message}
        </p>
      )}
      <button onClick={() => setCreateDispute(false)} className={styles.back}>
        <BackIcon /> Вернуться назад
      </button>
    </>
  );
}
