import { IWrapComponent } from "@/utils/types/shared";
import classNames from "classnames";
import React from "react";

import styles from "./styles.module.scss";

interface ISubHeading extends IWrapComponent {
  rightElement?: React.ReactElement;
  type?: "default";
}
export default function SubHeading(props: ISubHeading) {
  const { children, rightElement, type } = props;
  return (
    <h3
      className={classNames(
        styles.container,
        type === "default" && styles.default
      )}
    >
      {children}
      {rightElement && rightElement}
    </h3>
  );
}
