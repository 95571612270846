import React, { useRef, useState } from "react";

import styles from "./styles.module.scss";

import { ReactComponent as UsersPlus } from "@assets/icons/users-plus.svg";
import { ReactComponent as UsersEdit } from "@assets/icons/users-edit.svg";
import { ReactComponent as UsersUp } from "@assets/icons/users-up.svg";
import Heading from "@/components/typography/Heading/Heading";
import useOnClickOutside from "@/utils/hooks/useClickOutside";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function MassPayoutsHead() {
  const [isDdVisible, setIsDdVisible] = useState(false);

  const { t } = useTranslation();

  const ddRef = useRef<HTMLUListElement>(null);
  const togglerRef = useRef<HTMLButtonElement>(null);

  function hideDd() {
    setIsDdVisible(false);
  }

  useOnClickOutside(ddRef, hideDd, togglerRef);

  function toggleVisible() {
    setIsDdVisible((curVal) => !curVal);
  }

  return (
    <div className={styles.title}>
      <Heading>{t("payoutsPage.title")}</Heading>

      <div>
        <div className={classNames(styles.container)}>
          <button
            ref={togglerRef}
            className={styles.button}
            onClick={toggleVisible}
          >
            <UsersPlus width={20} height={20} />
            <span className={styles.selectedItem}>
              {t("payoutsPage.dropDown.title")}
            </span>
          </button>
          <ul
            ref={ddRef}
            className={classNames(styles.dropDown, {
              [styles.visible]: isDdVisible,
            })}
          >
            <li>
              <Link
                className={classNames(styles.item)}
                to={"create"}
                onClick={hideDd}
              >
                <UsersEdit />
                <span> {t("payoutsPage.dropDown.params.handle")}</span>
              </Link>
            </li>
            <li className={classNames(styles.item)}>
              <UsersUp />
              <span> {t("payoutsPage.dropDown.params.import")} </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
