import { IP2PFields } from "../../utils/types/filterTransactions";
import { createApi } from "@reduxjs/toolkit/query/react";
import baseQueryWithToken from "../base-query";
import {
  ITransactionListResponse,
  ITransactionResponse,
} from "./transactions.types";
import addTransactionsQueryParams from "@/utils/helpers/addTransactionsQueryParams";

export interface ITransactionListParams extends Partial<IP2PFields> {
  page?: number;
  state?: string[];
  type?: string;
  disputes?: number;
  id?: string;
  externalId?: string | null;
}

export const transactionsApi = createApi({
  reducerPath: "transactionsApi",
  baseQuery: baseQueryWithToken,
  endpoints: (builder) => ({
    getTransactionsList: builder.query<
      ITransactionListResponse,
      ITransactionListParams
    >({
      query: (params) => {
        return `/api/v1/payment_transactions?&page=${
          params.page
        }${addTransactionsQueryParams(params)}`;
      },
    }),
    getTransaction: builder.query<ITransactionResponse, { id: string }>({
      query: (params) => `/api/v1/payment_transactions/${params.id}`,
    }),
  }),
});

export const { useGetTransactionsListQuery, useGetTransactionQuery } =
  transactionsApi;
