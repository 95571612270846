import React from "react";

import styles from "./styles.module.scss";

import logo from "@assets/icons/logo.svg";

export default function AppLogo() {
  return (
    <div className={styles.container}>
      <img src={logo} alt="logo" />
      <span className={styles.title}>FINANA</span>
    </div>
  );
}
