import React from "react";

import styles from "./styles.module.scss";

import Heading from "@/components/typography/Heading/Heading";
import { ReactComponent as WalletIcon } from "@assets/icons/wallet.svg";
import BalanceGeneral from "@/components/shared/BalanceGeneral/BalanceGeneral";
import { useTranslation } from "react-i18next";
import DoughnutChart from "@/components/charts/DoughnutChart/DoughnutChart";

export default function BalancesHead() {
  const { t } = useTranslation();
  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <Heading>{t("balancesPage.title")}</Heading>
        <button disabled className={styles.button}>
          <WalletIcon />
          <span> {t("balancesPage.button")} </span>
        </button>
      </div>
      <div className={styles.grid}>
        <div>
          <BalanceGeneral />
        </div>
        <div className={styles.details}>
          <p className={styles.detailsTitle}>Детализация по валютам</p>
          <DoughnutChart />
        </div>
      </div>
    </div>
  );
}
