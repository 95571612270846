import React, { useEffect, useState } from "react";

import { ReactComponent as CancelIcon } from "@assets/icons/cancel.svg";
import { ReactComponent as BackIcon } from "@assets/icons/flip-backward.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { useGetTransactionQuery } from "@/store/transactions/transactions.api";
import { getTransactionType } from "@/utils/helpers/transactions";
import { ReactComponent as DisputesIcon } from "@assets/icons/alert-triangle.svg";
import { parseDate } from "@/utils/helpers/parseDate";
import AvatarIconSwitcher from "@/components/transactions/AvatarIconSwitcher/AvatarIconSwitcher";
import ModalBase from "../ModalBase/ModalBase";
import ModalHead from "../ModalBase/ModalHead/ModalHead";
import ModalLoader from "./ModalLoader/ModalLoader";
import ModalBody from "./ModalBody/ModalBody";
import ModalContent from "../ModalBase/ModalContent/ModalContent";
import useAvatarBadgeSwitcher from "@/utils/hooks/useAvatarBadgeSwither";
import Skeleton from "react-loading-skeleton";

interface IModalComponent {
  id: string;
}

export default function ModalComponent(props: IModalComponent) {
  const [createDispute, setCreateDispute] = useState(false);
  const [isDisputeCreated, setIsDisputeCreated] = useState(false);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const dividedPath = pathname.split("/");
  const id = dividedPath[dividedPath.length - 1];

  const { data, isLoading } = useGetTransactionQuery({ id });
  const { avatarBadgeSwitcher } = useAvatarBadgeSwitcher();

  function closeModal() {
    navigate(-1);
  }

  const type = (() => {
    if (isLoading) {
      return "loading";
    }
    if (createDispute) {
      return "warning";
    }
    return data?.data ? getTransactionType(data.data) : "primary";
  })();
  function getIconHead() {
    if (type === "loading") {
      return <Skeleton height={36} width={36} style={{ borderRadius: 18 }} />;
    }
    if (createDispute) {
      return <DisputesIcon />;
    }
    return (
      <AvatarIconSwitcher
        short_type={data?.data.short_type || ""}
        state={data?.data.state || "created"}
        disputes={data?.data.disputes || []}
      />
    );
  }
  return (
    <ModalBase>
      <ModalHead
        type={type}
        closeModal={
          createDispute && !isDisputeCreated
            ? () => setCreateDispute(false)
            : closeModal
        }
        closeIcon={
          createDispute && !isDisputeCreated ? <BackIcon /> : <CancelIcon />
        }
        avatarIcon={getIconHead()}
        avatarBadgeContent={
          type === "loading"
            ? null
            : avatarBadgeSwitcher({
                disputes: data?.data.disputes || [],
                short_type: data?.data.short_type || "",
                state: data?.data.state || "created",
              })
        }
        text={
          data?.data.updated_at && !createDispute
            ? parseDate(data?.data.updated_at, "default", true)
            : ""
        }
      />
      <ModalContent>
        <>
          {isLoading ? (
            <ModalLoader />
          ) : (
            <ModalBody
              type={type}
              createDispute={createDispute}
              data={data}
              setCreateDispute={setCreateDispute}
              setIsDisputeCreated={setIsDisputeCreated}
              isDisputeCreated={isDisputeCreated}
            />
          )}
        </>
      </ModalContent>
    </ModalBase>
  );
}
