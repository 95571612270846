import React from "react";

import ModalCard, { IModalCard } from "@/components/cards/ModalCard/ModalCard";

import styles from "./styles.module.scss";
import classNames from "classnames";

export default function ModalCardReason(props: Partial<IModalCard>) {
  const { children } = props;
  return (
    <div
      className={classNames(styles.wrapper, {
        [styles.empty]: !children,
      })}
    >
      <ModalCard {...props}>{children ? children : "Отсутствует"}</ModalCard>
    </div>
  );
}
