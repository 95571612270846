import React from "react";

import sharedStyles from "@utils/styles/sharedStyles.module.scss";

import MassPayoutsHead from "./MassPayoutsHead/MassPayoutsHead";
import MassPayoutsAccounts from "./MassPayoutsAccounts/MassPayoutsAccounts";
import MassPayoutsStats from "./MassPayoutsStats/MassPayoutsStats";
import TransactionsList from "@/components/transactions/TransactionsList/TransactionsList";
import { Outlet } from "react-router-dom";

export default function MassPayouts() {
  return (
    <div className={sharedStyles.pageContainer}>
      <Outlet />
      <MassPayoutsHead />
      <MassPayoutsAccounts />
      <MassPayoutsStats />
      {/* <TransactionsList /> */}
    </div>
  );
}
