import React, { useMemo } from "react";

import CalculatedValue from "@/components/shared/CalculatedValue/CalculatedValue";
import { detectCardType } from "@/utils/helpers/detectCardType";
import { IClientItem } from "../../ModalPayout";

import styles from "../styles.module.scss";
import PaySystemIconSwitcher from "./PaySystemIconSwitcher/PaySystemIconSwitcher";

interface IClientItemProps {
  index: number;
  client: IClientItem;
}

export default function ClientItemCard(props: IClientItemProps) {
  const { client, index } = props;

  const hiddenCardNumber = useMemo(
    () =>
      function () {
        const cardNumber = client.cardNumber.replaceAll(" ", "");
        const left = cardNumber.slice(0, 6);
        const right = cardNumber.slice(cardNumber.length - 4);
        return `${left}******${right}`;
      },
    [client.cardNumber]
  );
  return (
    <div key={index} className={styles.clientContainer}>
      <div className={styles.clientHeader}>
        <p>Клиент {index + 1}</p>
        <CalculatedValue
          type={"accent"}
          value={`₽ ${client.amount}`}
          mode="money"
          size="sm"
        />
      </div>

      <div className={styles.clientFooter}>
        <p className={styles.id}>{client.clientId}</p>
        <p className={styles.cardNumber}>
          {detectCardType(client.cardNumber) && (
            <span className={styles.paySystem}>
              <PaySystemIconSwitcher
                paySystem={detectCardType(client.cardNumber)}
              />
            </span>
          )}
          <span>{hiddenCardNumber()}</span>
        </p>
      </div>
    </div>
  );
}
