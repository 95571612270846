import i18n from "i18next";

export const transactionsStatusFields = [
  {
    state: "created",
    value: i18n.t("transactionsPage.filterByStatus.statuses.created"),
    selected: false,
  },
  {
    state: "confirmed",
    value: i18n.t("transactionsPage.filterByStatus.statuses.success"),
    selected: false,
  },
  {
    state: "failed",
    value: i18n.t("transactionsPage.filterByStatus.statuses.cancel"),
    selected: false,
  },
  {
    state: "pending",
    value: i18n.t("transactionsPage.filterByStatus.statuses.pending"),
    selected: false,
  },
  // {
  //   state: "dispute",
  //   value: i18n.t("transactionsPage.filterByStatus.statuses.dispute"),
  //   selected: false,
  //   dispute: 1,
  // },
];
