import baseQueryWithToken from "../base-query";
import { createApi } from "@reduxjs/toolkit/query/react";

interface ICreateData {
  //   id: number;
  amount: string;
  comment: null | string;
  //   state: string;
  //   result_comment: null;
  payment_transaction_id: string;
  //   created_at: string;
  proofs: any[];
}
interface ICreateResponse {
  id: number;
  amount: string;
  comment: null | string;
  state: string;
  result_comment: null;
  created_at: string;
  payment_transaction_id: string;
  proofs: {
    url: string;
  }[];
}

export const disputesApi = createApi({
  reducerPath: "disputesApi",
  baseQuery: baseQueryWithToken,
  endpoints: (builder) => ({
    createDispute: builder.mutation<{ data: ICreateResponse }, ICreateData>({
      query: (data) => {
        let formData = new FormData();
        formData.append("amount", data.amount);
        data.proofs.forEach((proof, index) =>
          formData.append("proofs[]", data.proofs[index])
        );
        formData.append("payment_transaction_id", data.payment_transaction_id);
        data.comment && formData.append("comment", data.comment);

        return {
          url: `/api/v1/disputes`,
          method: "POST",
          body: formData,
        };
      },
    }),
  }),
});

export const { useCreateDisputeMutation } = disputesApi;
