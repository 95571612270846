import React from "react";

import styles from "./styles.module.scss";

import { ReactComponent as DangerArrow } from "@assets/icons/danger-arrow.svg";
import { ReactComponent as AccentArrow } from "@assets/icons/accent-arrow.svg";
import { ReactComponent as CircleArrow } from "@assets/icons/arrow-right-circle.svg";
import { useTranslation } from "react-i18next";
import SubHeading from "@/components/typography/SubHeading/SubHeading";
import Card from "@/components/cards/Card/Card";
import CardWithNavigation from "@/components/cards/CardWithNavigation/CardWithNavigation";

export default function MassPayoutsStats() {
  const { t } = useTranslation();
  return (
    <>
      <SubHeading>Статистика по массовым выплатам</SubHeading>
      <div className={styles.stats}>
        <Card
          title={t("payoutsPage.stats.today.title") as string}
          titleElement={<AccentArrow />}
          body="₽ 13 349 999,00"
          footer={`2 938 ${t("payoutsPage.stats.today.footer")}`}
          footerElement={<span>+10%</span>}
        />
        <Card
          type="danger"
          title={t("payoutsPage.stats.sum.title") as string}
          titleElement={<DangerArrow />}
          body="₽ 45 312 245,21"
          footer={`1298 ${t("payoutsPage.stats.sum.footer")}`}
          footerElement={<span>-10%</span>}
        />
        <Card
          title={t("payoutsPage.stats.balance.title") as string}
          body="₽ 423 396,32"
          footer={`10 ${t("payoutsPage.stats.balance.footer")}`}
          footerElement={<span>+10%</span>}
        />
      </div>
    </>
  );
}
