import { useState } from "react";

import styles from "./styles.module.scss";

import classNames from "classnames";
import Button from "@/components/controls/Button/Button";
import Input from "@/components/forms/Input/Input";
import { ReactComponent as MailIcon } from "@assets/icons/mail.svg";
import { ReactComponent as LockIcon } from "@assets/icons/lock.svg";
import { ReactComponent as EyeIcon } from "@assets/icons/eye.svg";
import { ReactComponent as EyeOff } from "@assets/icons/eye-off.svg";
import { useGetAuthTokenMutation } from "@/store/auth/auth.api";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { setAccessToken } from "@/services/cookies/cookies";
import { authData } from "@/utils/types/shared";

export default function AuthForm() {
  const [isPasswordVisible, setPasswordVisible] = useState(false);
  const [responseError, setResponseError] = useState<null | string>(null);

  const [auth, { error, isLoading }] = useGetAuthTokenMutation();

  const navigate = useNavigate();

  function togglePasswordVisible() {
    setPasswordVisible((isVisible) => !isVisible);
  }

  async function onFormSubmit(data: authData) {
    try {
      const authData = (await auth(data)) as any;

      if (authData?.error) {
        throw authData?.error;
      }
      setAccessToken(authData?.data?.access_token, authData?.data?.exp);
      navigate("/");
    } catch (err: any) {
      console.log(err);
      if (err?.status === 401) setResponseError("Неверный логин или пароль");
    }
  }

  // function authValidate(values: authData) {
  //   const errors = {} as any;
  //   if (!values.username) {
  //     errors.username = "Заполните поле";
  //   } else if (values.username.length < 3) {
  //     errors.username = "Слишком короткий логин";
  //   }

  //   if (!values.password) {
  //     errors.password = "Заполните поле";
  //   }

  //   return errors;
  // }

  const formik = useFormik({
    initialValues: { username: "", password: "" },
    // validate: authValidate,

    onSubmit: onFormSubmit,
  });

  function checkButtonDisabled() {
    const errorValues = Object.values(formik?.errors);
    return Boolean(errorValues?.find((errorVal) => errorVal?.length > 1));
  }

  function getInputType(fieldError?: string, fieldValue?: string) {
    if (responseError) {
      return "error";
    }
    if (Boolean(fieldError)) {
      return "error";
    }
    if (fieldValue?.length === 0) {
      return "empty";
    }
  }

  return (
    <div className={classNames(styles.container, false && styles.error)}>
      <h3 className={styles.title}>Авторизация</h3>
      <p className={styles.subTitle}>Введите данные для входа</p>

      <form
        onSubmit={formik.handleSubmit}
        className={classNames(styles.form, { [styles.error]: responseError })}
        autoComplete="off"
      >
        <label
          className={classNames(styles.label, {
            [styles.error]: Boolean(formik.errors?.username),
          })}
        >
          Логин
        </label>
        <Input
          type={getInputType(formik.errors?.username, formik.values?.username)}
          inputAttributes={{
            maxLength: 50,
            type: "text",
            placeholder: "finana",
            name: "username",
            value: formik.values.username,
            onChange: formik.handleChange,
          }}
          leftElement={<MailIcon />}
        />
        {formik.errors.username && (
          <p className={styles.errorText}>{formik.errors.username}</p>
        )}
        <label
          className={classNames(styles.label, {
            [styles.error]: formik.errors?.password,
          })}
        >
          Пароль
        </label>
        <Input
          type={getInputType(formik.errors?.password, formik.values?.password)}
          inputAttributes={{
            maxLength: 50,
            name: "password",
            value: formik.values.password,
            onChange: formik.handleChange,
            type: isPasswordVisible ? "text" : "password",
            placeholder: "*************",
            autoComplete: "new-password",
          }}
          leftElement={<LockIcon />}
          rightElement={
            <button
              className={styles.passwordToggler}
              type="button"
              onClick={togglePasswordVisible}
            >
              {isPasswordVisible ? <EyeOff /> : <EyeIcon />}
            </button>
          }
        />
        {formik.errors.password && (
          <p className={styles.errorText}>{formik.errors.password}</p>
        )}
        {responseError && (
          <div className={styles.errorText}>{responseError}</div>
        )}
        <div className={styles.gap} />
        <Button loading={isLoading} disabled={checkButtonDisabled()}>
          Авторизоваться
        </Button>
      </form>
    </div>
  );
}
