import React from "react";

import ModalCard from "@/components/cards/ModalCard/ModalCard";
import Chip from "@/components/shared/Chip/Chip";
import ModalCardData from "../ModalCardData/ModalCardData";
import ModalCardReason from "../ModalCardReason/ModalCardReason";
import { ReactComponent as MasterCardIcon } from "@assets/icons/master-card.svg";

import styles from "./styles.module.scss";
import { ITransactionListItem } from "@/store/transactions/transactions.types";
import { useTranslation } from "react-i18next";
import { parseDate } from "@/utils/helpers/parseDate";

interface IModalCards
  extends Partial<
    Pick<
      ITransactionListItem,
      | "payer_card_bank_issuer"
      | "disputes"
      | "created_at"
      | "payer_card_number"
      | "payer_card_brand"
      | "material"
    >
  > {}

export default function ModalCards(props: IModalCards) {
  const {
    created_at,
    disputes,
    payer_card_bank_issuer,
    payer_card_number,
    payer_card_brand,
    material,
  } = props;

  const { t } = useTranslation();

  const disputeTypes = {
    opened: {
      type: "warning",
      text: t("modals.transaction.cards.disputes.opened"),
    },
    accepted: {
      type: "successLight",
      text: t("modals.transaction.cards.disputes.accepted"),
    },
    closed: {
      type: "danger",
      text: t("modals.transaction.cards.disputes.closed"),
    },
  };

  return (
    <>
      {disputes && disputes?.length > 0 && (
        <div className={styles.topCards}>
          <ModalCard
            title={t("modals.transaction.cards.disputes.title") as string}
          >
            <div className={styles.disputesHistory}>
              {disputes.map((dispute, index) => (
                <Chip
                  key={dispute.id}
                  type={
                    disputeTypes[dispute?.state as "opened"].type as "danger"
                  }
                >
                  {parseDate(dispute.created_at) +
                    " - " +
                    disputeTypes[dispute?.state as "opened"].text +
                    (index === 1 ? " x2" : "")}
                </Chip>
              ))}
            </div>
          </ModalCard>
        </div>
      )}
      <div className={styles.cards}>
        {payer_card_number && (
          <ModalCardData
            title={t("modals.transaction.cards.payer.cardNumber") as string}
            cardIcon={
              payer_card_brand === "visa" ? <MasterCardIcon /> : undefined
            }
          >
            {payer_card_number}
          </ModalCardData>
        )}
        {payer_card_bank_issuer && (
          <ModalCardData
            title={t("modals.transaction.cards.payer.cardIssuer") as string}
          >
            {payer_card_bank_issuer}
          </ModalCardData>
        )}
        {material?.card_number && (
          <ModalCardData
            title={t("modals.transaction.cards.material.cardNumber") as string}
            cardIcon={
              material?.card_brand === "visa" ? <MasterCardIcon /> : undefined
            }
          >
            {material?.card_number}
          </ModalCardData>
        )}

        {/* <ModalCardData title={t("modals.transaction.cards.payer.id") as string}>
          0000000000000
        </ModalCardData> */}
      </div>
      <div className={styles.bottomCards}>
        {created_at && (
          <ModalCard title={t("modals.transaction.cards.payer.date") as string}>
            {/* {moment(created_at).locale("ru").calendar()} */}
            {parseDate(created_at, "default", true)}
          </ModalCard>
        )}
        {/* <ModalCard title="Тип операции">
          <>
            Пополнение счета <Chip type="accent">#RUP2P01221076245</Chip>
          </>
        </ModalCard>
        <ModalCard title="Редирект после оплаты">
          <Chip type="accent" link="/success-page/12345">
            /success-page/12345
          </Chip>
        </ModalCard> */}
        {disputes && disputes?.length > 0 && (
          <ModalCardReason title="Причина отклонения">
            {disputes[disputes?.length]?.result_comment}
          </ModalCardReason>
        )}
      </div>
    </>
  );
}
