import React from "react";

import styles from "./styles.module.scss";

import Card from "@/components/cards/Card/Card";
import SubHeading from "@/components/typography/SubHeading/SubHeading";
import { ReactComponent as InfoIcon } from "@assets/icons/info-circle.svg";
import { ReactComponent as ArrowIcon } from "@assets/icons/chevron-right.svg";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import "swiper/css";
import "swiper/css/navigation";

export default function BalancesAll() {
  const { t } = useTranslation();

  const navigationPrevRef = React.useRef(null);
  const navigationNextRef = React.useRef(null);

  return (
    <div className={styles.container}>
      <SubHeading type="default">{t("balancesPage.allBalances")}</SubHeading>
      <div className={styles.listContainer}>
        <div className={styles.prev} ref={navigationPrevRef}>
          <ArrowIcon />
        </div>
        <div className={styles.next} ref={navigationNextRef}>
          <ArrowIcon />
        </div>
        <Swiper
          modules={[Navigation]}
          className={styles.list}
          slidesPerView={3}
          navigation={true}
          spaceBetween={20}
          grabCursor={true}
          //   @ts-ignore
          navigation={{
            prevEl: navigationPrevRef.current,
            nextEl: navigationNextRef.current,
          }}
          onBeforeInit={(swiper: any) => {
            swiper.params.navigation.prevEl = navigationPrevRef.current;
            swiper.params.navigation.nextEl = navigationNextRef.current;
          }}
        >
          <SwiperSlide>
            <Card
              title={"Баланс эквайринга"}
              titleElement={<InfoIcon />}
              body="₽ 13 349 999.00"
              bodyLeftSign="≈"
              footer={
                <span className={styles.cardFooterText}>
                  200 233,11 USDT TRC-20
                </span>
              }
            />
          </SwiperSlide>
          <SwiperSlide>
            <Card
              title={"Баланс эквайринга"}
              titleElement={<InfoIcon />}
              body="₽ 13 349 999.00"
              bodyLeftSign="≈"
              footer={
                <span className={styles.cardFooterText}>
                  200 233,11 USDT TRC-20
                </span>
              }
            />
          </SwiperSlide>
          <SwiperSlide>
            <Card
              title={"Баланс эквайринга"}
              titleElement={<InfoIcon />}
              body="₽ 13 349 999.00"
              bodyLeftSign="≈"
              footer={
                <span className={styles.cardFooterText}>
                  200 233,11 USDT TRC-20
                </span>
              }
            />
          </SwiperSlide>
          <SwiperSlide>
            <Card
              title={"Баланс эквайринга"}
              titleElement={<InfoIcon />}
              body="₽ 13 349 999.00"
              bodyLeftSign="≈"
              footer={
                <span className={styles.cardFooterText}>
                  200 233,11 USDT TRC-20
                </span>
              }
            />
          </SwiperSlide>
          <SwiperSlide>
            <Card
              title={"Баланс эквайринга"}
              titleElement={<InfoIcon />}
              body="₽ 13 349 999.00"
              bodyLeftSign="≈"
              footer={
                <span className={styles.cardFooterText}>
                  200 233,11 USDT TRC-20
                </span>
              }
            />
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
}
