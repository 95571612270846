import React from "react";

import styles from "./styles.module.scss";

import { IWrapComponent } from "@/utils/types/shared";

export default function Heading(props: IWrapComponent) {
  const { children } = props;
  return <h2 className={styles.heading}>{children}</h2>;
}
