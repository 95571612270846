import ModalCardLoader from "@/components/cards/ModalCard/ModalCardLoader/ModalCardLoader";
import React from "react";

import styles from "../styles.module.scss";

export default function ModalCardsLoader() {
  return (
    <>
      <div className={styles.cards}>
        <ModalCardLoader />
        <ModalCardLoader />
      </div>
      <div className={styles.bottomCards}>
        <ModalCardLoader />
      </div>
    </>
  );
}
