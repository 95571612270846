import React from "react";

import styles from "./styles.module.scss";

import { ReactComponent as AccentArrow } from "@assets/icons/accent-arrow.svg";
import { ReactComponent as CircleArrow } from "@assets/icons/arrow-right-circle.svg";
import { ReactComponent as DangerArrow } from "@assets/icons/danger-arrow.svg";
import { ReactComponent as ActivityIcon } from "@assets/icons/activity.svg";
import { ReactComponent as ActivitySuccessIcon } from "@assets/icons/activity-success.svg";
import { ReactComponent as SwapIcon } from "@assets/icons/swap.svg";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { HorizontalChart } from "@/components/charts/HorizontalChart/HorizontalChart";
import { VerticalChart } from "@/components/charts/VerticalChart/VerticalChart";
import classNames from "classnames";
import Card from "@/components/cards/Card/Card";
import SubHeading from "@/components/typography/SubHeading/SubHeading";
import CardWithNavigation from "@/components/cards/CardWithNavigation/CardWithNavigation";
import CardWithEllipse from "@/components/cards/CardWithEllipse/CardWithEllipse";
import PercentageCircle from "@/components/shared/PercentageCircle/PercentageCircle";
import Avatar from "@/components/shared/Avatar/Avatar";

export default function TransactionsStats() {
  const { t } = useTranslation();

  return (
    <>
      <div className={styles.cards}>
        <div className={classNames(styles.subTitle)}>
          <SubHeading>Статистика по транзакциям</SubHeading>
        </div>
        <div className={classNames(styles.chart, styles.horizontal)}>
          <HorizontalChart />
        </div>
        <div className={classNames(styles.chart, styles.vertical)}>
          <VerticalChart />
        </div>
        <div className={styles.card1}>
          <Card
            title={t("mainPage.stats.card1.title") as string}
            titleElement={<AccentArrow />}
            body="₽ 13 349 999.00"
            bodyLeftSign="≈"
            footer={`2 938 ${t("mainPage.stats.card1.footer")}`}
            footerElement={<span>+10%</span>}
          />
        </div>
        <div className={styles.card2}>
          <Card
            type="danger"
            title={t("mainPage.stats.card2.title") as string}
            titleElement={<DangerArrow />}
            body="₽ 45 312 245.21"
            bodyLeftSign="≈"
            footer={t("mainPage.stats.card2.footer") as string}
            footerElement={<span>-10%</span>}
          />
        </div>
        <div className={styles.card3}>
          <CardWithNavigation
            link="/home"
            title={t("mainPage.stats.card3.title") as string}
            titleElement={<CircleArrow />}
            body="₽ 423 396.32"
            bodyLeftSign="≈"
            footer="5 107 USDT TRC-20"
            footerElement={<span>+10%</span>}
          />
        </div>
        <div className={styles.card4}>
          <CardWithNavigation
            link="/home"
            title={t("mainPage.stats.card4.title") as string}
            titleElement={<CircleArrow />}
            body="₽ 159 176.54"
            bodyLeftSign="≈"
            footer={`112 ${t("mainPage.stats.card1.footer")}`}
            footerElement={<span>+10%</span>}
          />
        </div>
        <div className={styles.card5}>
          <CardWithEllipse
            title={t("mainPage.stats.card5.title")}
            ellipse={
              <PercentageCircle percentage={25}>
                <ActivityIcon />
              </PercentageCircle>
            }
            body="25,45%"
          />
        </div>
        <div className={styles.card6}>
          <CardWithEllipse
            title={t("mainPage.stats.card6.title")}
            ellipse={
              <PercentageCircle percentage={75} type="success">
                <ActivitySuccessIcon />
              </PercentageCircle>
            }
            body="75,45%"
          />
        </div>
        <div className={styles.card7}>
          <CardWithEllipse
            title={t("mainPage.stats.card7.title")}
            ellipse={
              <Avatar>
                <SwapIcon />
              </Avatar>
            }
            body="99 024"
          />
        </div>
        <div className={styles.card8}>
          <CardWithEllipse
            title={t("mainPage.stats.card8.title")}
            ellipse={
              <Avatar type="success">
                <SwapIcon />
              </Avatar>
            }
            body="112 млн"
          />
        </div>
      </div>
    </>
  );
}
