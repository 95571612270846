import React, { useState, useEffect } from "react";

import CheckBox from "@/components/forms/CheckBox/CheckBox";
import { transactionsStatusFields } from "@/utils/constants/statusFilterFields";
import { useTranslation } from "react-i18next";
import { useNavigate, useNavigation, useParams } from "react-router-dom";
import FilterBase from "../FilterBase/FilterBase";

interface IFilterByStatus {
  titlePlaceholder: string;
  setStateQueryParameters: React.Dispatch<React.SetStateAction<string[]>>;
}

export default function FilterByStatus(props: IFilterByStatus) {
  const { titlePlaceholder, setStateQueryParameters } = props;

  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();

  const [items, setItems] = useState(transactionsStatusFields);

  function onSubmit() {
    const selected = items.filter((item) => item.selected);
    // const disputes = selected.find((item) => item?.dispute);
    setStateQueryParameters(
      selected.map((item) => item.state).filter((i) => i !== "dispute")
    );
  }

  useEffect(() => {
    setItems([...transactionsStatusFields]);
    setStateQueryParameters(transactionsStatusFields.map((item) => item.state));
  }, [params.disputes]);

  function getSelectedItems() {
    return items
      .filter((item) => item.selected)
      .map((item) => item.value)
      .join(", ");
  }
  return (
    <FilterBase
      onSubmit={onSubmit}
      title={getSelectedItems()}
      titlePlaceholder={titlePlaceholder}
      controlsType="apply"
    >
      <CheckBox items={items} setItems={setItems} />
    </FilterBase>
  );
}
