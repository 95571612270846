import React from "react";

import { ReactComponent as PlusIcon } from "@assets/icons/plus.svg";
import { ReactComponent as CheckIcon } from "@assets/icons/check.svg";
import { ReactComponent as CancelIcon } from "@assets/icons/cancel.svg";
import { ReactComponent as CardIcon } from "@assets/icons/card.svg";
import { ReactComponent as ClockIcon } from "@assets/icons/clock.svg";
import { ReactComponent as SwapIcon } from "@assets/icons/swap-small.svg";
import { ReactComponent as DisputesIcon } from "@assets/icons/alert-triangle.svg";
import { ITransactionListItem } from "@/store/transactions/transactions.types";

interface IAvatarSwitcher {
  short_type: string;
  state: string;
}

export default function AvatarIconSwitcher(
  props: Pick<ITransactionListItem, "short_type" | "state" | "disputes">
) {
  const { short_type, state, disputes } = props;

  function c2cSwitcher() {
    switch (state) {
      case "confirmed": {
        return <CheckIcon />;
      }
      case "created": {
        return <PlusIcon />;
      }
      case "pending": {
        return <ClockIcon />;
      }
      case "failed":
        return <CancelIcon />;
      default:
        return <span>нет статуса</span>;
    }
  }
  if (short_type === "c2c" && disputes.length > 0) {
    return <DisputesIcon />;
  }
  if (short_type === "p2p") return <SwapIcon />;
  return c2cSwitcher();
}
