import React, { useEffect, useState, useRef } from "react";

import styles from "./styles.module.scss";

import {
  transactionsApi,
  useGetTransactionQuery,
} from "@/store/transactions/transactions.api";
import TransactionsListMapper from "../TransactionsListMapper/TransactionsListMapper";
import { IP2PFields } from "@/utils/types/filterTransactions";
import { useInView } from "react-intersection-observer";
import Loader from "@/components/shared/Loader/Loader";

interface ITransactionsList {
  stateQueryParameters?: string[];
  type?: string;
  id?: string | null;
  externalId?: string | null;
  filterQueries?: Partial<IP2PFields>;
  withoutInfiniteScroll?: boolean;
  disputes?: number;
}

export default function TransactionsList(props: ITransactionsList) {
  const {
    stateQueryParameters,
    type,
    id,
    externalId,
    filterQueries,
    withoutInfiniteScroll,
    disputes,
  } = props;

  const [transactionsData, setTransactionsData] = useState<any[]>([]);

  let params = {
    page: 1,
    disputes: disputes,
    state: stateQueryParameters,
    type: type === "all" ? "" : type,
    externalId: externalId,
    amountGt: filterQueries?.amountGt,
    amountLt: filterQueries?.amountLt,
    currency: filterQueries?.currency,
    payerCardNumber: filterQueries?.payerCardNumber?.replaceAll(" ", ""),
    payerCardBrand: filterQueries?.payerCardBrand?.toLowerCase(),
    payerCardBankAlias: filterQueries?.payerCardBankAlias,
    sellerCardBankAlias: filterQueries?.sellerCardBankAlias,
    sellerCardBrand: filterQueries?.sellerCardBrand?.toLowerCase(),
    sellerCardNumber: filterQueries?.sellerCardNumber?.replaceAll(" ", ""),
    dateGt:
      filterQueries?.dateGt && new Date(filterQueries?.dateGt).toISOString(),
    dateLt:
      filterQueries?.dateLt && new Date(filterQueries?.dateLt).toISOString(),
  };

  const [getTransactionsList, data] =
    transactionsApi.endpoints.getTransactionsList.useLazyQuery();
  const {
    data: singleTransaction,
    isLoading: singleTransactionLoading,
    error: singleTransactionError,
  } = useGetTransactionQuery(
    {
      id: id as string,
    },
    { skip: !id }
  );

  async function getTransactions(page: number) {
    const resp = await getTransactionsList({ ...params, page: page });
    let curData = resp.data?.data;
    if (page === 1) {
      curData && setTransactionsData(curData);
    } else {
      let curData = resp.data?.data;
      curData && setTransactionsData((current) => current.concat(curData));
    }
  }

  useEffect(() => {
    getTransactions(1);
  }, [
    type,
    params?.disputes,
    filterQueries,
    stateQueryParameters,
    id,
    externalId,
  ]);

  const { ref, inView } = useInView({ threshold: 0 });
  useEffect(() => {
    data.data?.meta?.next_page && getTransactions(data?.data?.meta?.next_page);
  }, [inView]);

  if (id) {
    return (
      <TransactionsListMapper
        data={singleTransaction?.data ? [singleTransaction?.data] : undefined}
        error={singleTransactionError}
        isLoading={singleTransactionLoading}
      />
    );
  }
  return (
    <>
      <TransactionsListMapper
        data={transactionsData}
        error={data?.error}
        // isLoading={data.isLoading}
      />

      {!withoutInfiniteScroll && data?.data?.meta?.next_page != null && (
        <div className={styles.observer} ref={ref}>
          <Loader />
        </div>
      )}
      {data?.data?.meta?.next_page == null && (
        <div className={styles.message}>Конец транзакций</div>
      )}
    </>
  );
}
