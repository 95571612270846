import React, { useState } from "react";

import styles from "./styles.module.scss";
import "./styles.overrides.scss";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import classNames from "classnames";
import ru from "date-fns/locale/ru";

import { ReactComponent as CalendarIcon } from "@assets/icons/calendar.svg";
import { useTranslation } from "react-i18next";

interface IDatePickerComponent {
  placeholder?: string;
  label?: string;
  onChange?: (date: Date | string) => void;
  selected?: string | Date;
}

export default function DatePickerComponent(props: IDatePickerComponent) {
  const { placeholder, label, onChange, selected } = props;

  const [startDate, setStartDate] = useState<Date | null>();
  const [isActive, setIsActive] = useState(false);
  const { t } = useTranslation();

  function dateChange(date: Date) {
    setStartDate(date);
    onChange && onChange(date);
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.headContainer}>
        <label className={styles.label}> {label} </label>
        <div className={styles.iconContainer}>
          <CalendarIcon
            className={classNames(styles.icon, { [styles.active]: isActive })}
          />
        </div>
      </div>
      <DatePicker
        onCalendarClose={() => setIsActive(false)}
        className={classNames(styles.container, { [styles.active]: isActive })}
        onFocus={() => setIsActive(true)}
        onBlur={() => setIsActive(false)}
        selected={(selected as Date) || startDate}
        onChange={dateChange}
        wrapperClassName="datePicker"
        customInput={<input className={classNames(styles.input)} />}
        locale={ru}
        placeholderText={placeholder}
        showTimeSelect
        timeCaption={t("transactionsPage.datePicker.timeCaption") as string}
        dateFormat="MMMM d, yyyy h:mm"
      />
    </div>
  );
}
