import React from "react";

import MasterCardIcon from "@assets/icons/master-card.svg";

export default function PaySystemIconSwitcher({
  paySystem,
}: {
  paySystem?: string;
}) {
  if (paySystem === "mastercard") {
    return <img src={MasterCardIcon} alt="MasterCardIcon" />;
  }
  return <>{paySystem}</>;
}
