import React from "react";

import styles from "./styles.module.scss";

import logo from "@assets/icons/logo.svg";
import Section from "./Section/Section";

import { ReactComponent as HomeIcon } from "@assets/icons/home.svg";
import { ReactComponent as TransactionsIcon } from "@assets/icons/bar-chart.svg";
import { ReactComponent as FileIcon } from "@assets/icons/file.svg";
import { ReactComponent as WalletIcon } from "@assets/icons/wallet.svg";
import { ReactComponent as UsersIcon } from "@assets/icons/users-plus.svg";
import { ReactComponent as AlertIcon } from "@assets/icons/alert-square.svg";
import { ReactComponent as AnnouncementIcon } from "@assets/icons/announcement.svg";
import { ReactComponent as SettingsIcon } from "@assets/icons/settings-02.svg";
import { ReactComponent as HelpIcon } from "@assets/icons/help-square.svg";
import { ReactComponent as TerminalIcon } from "@assets/icons/terminal-square.svg";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function SideBar() {
  const { t } = useTranslation();

  return (
    <aside className={styles.container}>
      <Link to={"/"}>
        <div className={styles.logo}>
          <img src={logo} alt="logo" />
        </div>
      </Link>
      <div className={styles.content}>
        <Section
          title={t("navigation.sections.title")}
          routes={[
            // {
            //   route: "/",
            //   icon: <HomeIcon />,
            //   name: t("navigation.sections.main"),
            // },
            {
              route: [
                {
                  name: t("navigation.sections.transactions.all"),
                  route: "/transactions/all",
                },
                {
                  name: t("navigation.sections.transactions.p2p"),
                  route: "/transactions/PaymentTransaction::P2P",
                },
                {
                  name: t("navigation.sections.transactions.acquiring"),
                  route: "/transactions/PaymentTransaction::C2C",
                },
              ],
              icon: <TransactionsIcon />,
              name: t("navigation.sections.transactions.title"),
            },
            {
              route: [
                {
                  name: t("navigation.sections.transactions.all"),
                  route: "/reports",
                },
              ],
              icon: <FileIcon />,
              name: t("navigation.sections.reports.title"),
            },
            {
              route: "/balances",
              icon: <WalletIcon />,
              name: t("navigation.sections.balances"),
            },
            {
              route: "/mass-payouts",
              icon: <UsersIcon />,
              name: t("navigation.sections.massPayouts"),
            },
            {
              route: "/transactions/all/dispute/1",
              icon: <AlertIcon />,
              name: t("navigation.sections.transactionsControversial"),
            },
          ]}
        />
        <Section
          title={t("navigation.account.title")}
          routes={[
            {
              route: "/announcement",
              icon: <AnnouncementIcon />,
              name: t("navigation.account.announcement"),
              chipValue: "20",
            },
            {
              route: "/settings",
              icon: <SettingsIcon />,
              name: t("navigation.account.settings"),
            },
          ]}
        />
        <Section
          title={t("navigation.reference.title")}
          routes={[
            {
              route: "/api",
              icon: <TerminalIcon />,
              name: t("navigation.reference.api"),
            },
            {
              route: "/user",
              icon: <HelpIcon />,
              name: t("navigation.reference.help"),
            },
          ]}
        />
        <Section
          title={t("navigation.scoring.title")}
          type="currencies"
          currencies={[
            {
              currency: "RUB",
              amount: "₽ 1 326 653.92",
              account: "R 4143 6351 1482",
            },
            {
              currency: "USDT",
              amount: "$ 19 426.45",
              account: "U 4143 6351 1482",
            },
            {
              currency: "EUR",
              amount: "€ 18 723.70",
              account: "E 4143 6351 1482",
            },
          ]}
        />
      </div>
    </aside>
  );
}
