import React, { useRef, useState, useMemo } from "react";

import styles from "./styles.module.scss";

import { ReactComponent as Chevron } from "@assets/icons/chevron-down.svg";
import { ReactComponent as SettingsIcon } from "@assets/icons/settings-02.svg";
import { ReactComponent as HelpIcon } from "@assets/icons/help-square.svg";
import { ReactComponent as LogOutIcon } from "@assets/icons/log-out-01.svg";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { clearAccessToken } from "@/services/cookies/cookies";
import { IUserInfoResponse } from "@/store/user/user.api";
import userPlaceholder from "@assets/images/user-placeholder.png";
import useOnClickOutside from "@/utils/hooks/useClickOutside";
import classNames from "classnames";

interface IHeaderDd {
  data?: IUserInfoResponse;
}

export default function HeaderDd(props: IHeaderDd) {
  const { data } = props;
  const [isUserDdVisible, setUserDdVisible] = useState(false);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const ddRef = useRef<null | HTMLUListElement>(null);
  const ddBtn = useRef<null | HTMLButtonElement>(null);
  useOnClickOutside(ddRef, () => setUserDdVisible(false), ddBtn);

  const role = useMemo(() => {
    return data?.data?.roles.includes("merchant_owner")
      ? t("roles.admin")
      : t("roles.none");
  }, [data?.data?.roles]);

  function toggleUserDdVisible() {
    setUserDdVisible((curVal) => !curVal);
  }
  function logOut() {
    clearAccessToken();
    navigate("/auth");
  }
  return (
    <button
      ref={ddBtn}
      onClick={toggleUserDdVisible}
      className={classNames(styles.userContainer, {
        [styles.active]: isUserDdVisible,
      })}
    >
      <div className={styles.user}>
        <img src={userPlaceholder} alt="user" className={styles.image} />
        <div className={styles.info}>
          <span className={styles.title}>{data?.data.username}</span>
          <span className={styles.subTitle}>{role}</span>
        </div>
      </div>
      <Chevron
        className={classNames(styles.chevron, {
          [styles.active]: isUserDdVisible,
        })}
      />

      <div
        className={classNames(styles.dropDown, {
          [styles.active]: isUserDdVisible,
        })}
      >
        <div className={styles.ddContent}>
          <div className={styles.user}>
            <img src={userPlaceholder} alt="user" className={styles.image} />
            <div className={styles.info}>
              <span className={styles.title}>{data?.data.username}</span>
              <span className={styles.subTitle}>{data?.data.email}</span>
              <span className={styles.ddTitleText}>{role}</span>
            </div>
          </div>
          <ul className={styles.ddList} ref={ddRef}>
            <Link to="/settings">
              <li className={styles.item}>
                <SettingsIcon />
                <span>{t("header.dd.settings")}</span>
              </li>
            </Link>
            <Link to="/help">
              <li className={styles.item}>
                <HelpIcon />
                <span>{t("header.dd.help")}</span>
              </li>
            </Link>
            <li className={styles.item} onClick={logOut}>
              <LogOutIcon />
              <span>{t("header.dd.logOut")}</span>
            </li>
          </ul>
        </div>
      </div>
    </button>
  );
}
