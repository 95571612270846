import React, { useState } from "react";

import styles from "./styles.module.scss";

import { ReactComponent as ArrowIcon } from "@assets/icons/arrow-circle-down.svg";
import { StyleType } from "@/utils/types/shared";
import { IClientItem } from "../ModalPayout";
import Chip from "@/components/shared/Chip/Chip";
import ModalTitle from "../../ModalBase/ModalTitle/ModalTitle";
import CalculatedValue from "@/components/shared/CalculatedValue/CalculatedValue";
import ModalButtonClose from "../../Modal/ModalButtonClose/ModalButtonClose";
import ClientItemCard from "./ClientItemCard/ClientItemCard";

interface IModalPayoutSuccess {
  type: StyleType;
  selected: any;
  clients: IClientItem[];
}

export default function ModalPayoutSuccess(props: IModalPayoutSuccess) {
  const { type, selected, clients } = props;
  const [allClientsHidden, setAllClientsHidden] = useState(true);
  return (
    <>
      <ModalTitle>Выплата создана</ModalTitle>
      <p className={styles.subTitle}>
        <span>{selected?.accountTitle ?? ""}</span>
        <Chip type={type}>{selected?.id ?? ""}</Chip>
      </p>
      <div className={styles.amount}>
        <p className={styles.amountTitle}>Общая сумма</p>
        <div className={styles.amountValue}>
          <CalculatedValue
            value={"10000.00"}
            type={type}
            mode="money"
            size="md"
          />
        </div>
        <div className={styles.otherInfo}>
          <span className={styles.amountInUSDT}>311,04 USDT</span>
          <span className={styles.commission}>
            <CalculatedValue
              value={`₽ 300.00`}
              moneyPercentage={`1,5%`}
              mode="money"
              type={type}
              size="sm"
            />
          </span>
        </div>
      </div>

      <div className={styles.clients}>
        {clients
          .slice(0, allClientsHidden ? 3 : clients.length)
          .map((client, index) => (
            <ClientItemCard client={client} index={index} key={index} />
          ))}
        {allClientsHidden && clients.length > 3 && (
          <div className={styles.gradientRect} />
        )}
      </div>
      {allClientsHidden && clients.length > 3 && (
        <button
          className={styles.showButton}
          onClick={() => setAllClientsHidden(false)}
        >
          <ArrowIcon /> Показать полностью
        </button>
      )}
      <div className={styles.close}>
        <ModalButtonClose />
      </div>
    </>
  );
}
