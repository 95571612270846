export default function validateCardNumber(
  e: React.ChangeEvent<HTMLInputElement>,
  callBack: (val: string) => void,
  currentValue: string
) {
  let card = e.target.value;
  if (isNaN(+e.target.value.slice(-1))) {
    return;
  }
  if (card.length > 19) {
    return;
  }
  card =
    (card.trim().length + 1) % 5 === 0 &&
    card.length > 0 &&
    card.length < 19 &&
    card.length > currentValue.length
      ? card + " "
      : card;
  callBack(card);
}
