import React, { useState } from "react";

import styles from "../styles.module.scss";

import { ReactComponent as UsersPlus } from "@assets/icons/users-plus.svg";
import Card from "@/components/cards/Card/Card";
import Button from "@/components/controls/Button/Button";
import Chip from "@/components/shared/Chip/Chip";
import ControlBox from "@/components/controls/ControlBox/ControlBox";
import DropDown from "@/components/controls/DropDown/DropDown";
import ErrorBox from "@/components/shared/ErrorBox/ErrorBox";
import { useTranslation } from "react-i18next";
import ModalTitle from "../../ModalBase/ModalTitle/ModalTitle";
import ModalClient from "../ModalClient/ModalClient";
import {
  accounts,
  amountMinValue,
  IClientItem,
  initialAccountItem,
} from "../ModalPayout";
import classNames from "classnames";

interface IModalPayoutCreate {
  setClientsData: React.Dispatch<React.SetStateAction<IClientItem[]>>;
  clientsData: IClientItem[];
  setSuccessVisible: React.Dispatch<React.SetStateAction<boolean>>;
  selected: any;
  setSelected: React.Dispatch<any>;
}

export default function ModalPayoutCreate(props: IModalPayoutCreate) {
  const {
    clientsData,
    setClientsData,
    setSuccessVisible,
    selected,
    setSelected,
  } = props;
  const [errorsVisible, setErrorsVisible] = useState(false);
  const { t } = useTranslation();

  function addAccount() {
    setClientsData((cur) => cur.concat(initialAccountItem));
  }

  function onDdChange(val: string, index?: number) {
    setSelected(index != null ? accounts[index] : null);
  }

  function validateAccountsData() {
    return clientsData.map((account) => {
      const errors: any = {};
      if (account.paymentMethod.length === 0) {
        errors.paymentMethod = t("modals.payout.errors.method");
      }
      if (account.cardNumber.replaceAll(" ", "").length !== 16) {
        errors.cardNumber = t("modals.payout.errors.number");
      }
      if (+account.amount < amountMinValue) {
        errors.amount = `${t("modals.payout.errors.amount")} ${amountMinValue}`;
      }
      return { ...account, errors };
    });
  }
  function checkForErrors(data: IClientItem[]) {
    let isErrorsExist = false;
    data.forEach((account) => {
      if (Object.values(account.errors).length > 0) {
        isErrorsExist = true;
      }
    });
    return isErrorsExist;
  }
  function onSubmit() {
    const validatedData = validateAccountsData();
    setClientsData(validatedData);
    setErrorsVisible(true);
    if (!checkForErrors(validatedData)) {
      setSuccessVisible(true);
    }
  }

  return (
    <>
      <ModalTitle>{t("modals.payout.title")}</ModalTitle>
      <p className={styles.ddTitle}>{t("modals.payout.dd.title")} *</p>

      <div className={styles.dd}>
        <ControlBox>
          {selected != null && (
            <span
              className={styles.ddSelected}
            >{`${selected.accountTitle}, ${selected.currency}, ${selected.commission}`}</span>
          )}
          <span className={styles.muted}>
            <DropDown
              onChange={onDdChange}
              placeholder={t("modals.payout.dd.placeholder") as string}
              items={accounts.map(
                (i) => `${i.accountTitle} ${i.currency} ${i.commission}`
              )}
              selected={selected ? selected.id : undefined}
              mode="heightFull"
            />
          </span>
        </ControlBox>
      </div>
      {selected == null ? (
        <div className={styles.cardPlaceHolder}>
          {t("modals.payout.card.placeholder")}
        </div>
      ) : (
        <div className={styles.card}>
          <Card
            title={
              <div className={styles.cardTitle}>
                <Chip mode="currency" type="primary">
                  {selected.currency}
                </Chip>
                {selected.accountTitle}
              </div>
            }
            subTitle={selected.id}
            body={"₽ 13 349 999.00"}
            bodyPercent={"1.5%"}
            footer="1 231 выплат за все время"
            footerElement={
              <Chip mode="state" type="accent">
                Вкл
              </Chip>
            }
          />
        </div>
      )}
      <div
        className={classNames(styles.clients, {
          [styles.disabled]: Boolean(selected),
        })}
      >
        {clientsData.map((account, index) => (
          <ModalClient
            key={index}
            index={index}
            setClientsData={setClientsData}
            title={`${t("modals.payout.client.title")} #${index + 1}`}
            active={Boolean(selected)}
            account={account}
            errorsVisible={errorsVisible}
          />
        ))}
      </div>
      <div className={styles.controls}>
        <Button
          size="lg"
          type="primary"
          onClick={addAccount}
          disabled={!selected}
        >
          <UsersPlus width={24} height={24} className={styles.controlsIcon} />
          {t("modals.payout.add")}
        </Button>
        <ErrorBox
          errorText={
            errorsVisible && checkForErrors(clientsData)
              ? (t("modals.payout.errors.general") as string)
              : ""
          }
        >
          <Button size="lg" onClick={onSubmit} disabled={!selected}>
            {t("modals.payout.submit")}
          </Button>
        </ErrorBox>
      </div>
    </>
  );
}
