export const graphResponse = {
  current: [
    {
      date: "2022-12-08T15:00:00.000+03:00",
      sum_amount: "349996.0",
    },
    {
      date: "2022-12-08T14:00:00.000+03:00",
      sum_amount: "469037.0",
    },
    {
      date: "2022-12-08T13:00:00.000+03:00",
      sum_amount: "352111.0",
    },
    {
      date: "2022-12-08T12:00:00.000+03:00",
      sum_amount: "369701.0",
    },
    {
      date: "2022-12-08T11:00:00.000+03:00",
      sum_amount: "370708.0",
    },
    {
      date: "2022-12-08T10:00:00.000+03:00",
      sum_amount: "292699.0",
    },
    {
      date: "2022-12-08T09:00:00.000+03:00",
      sum_amount: "229209.0",
    },
    {
      date: "2022-12-08T08:00:00.000+03:00",
      sum_amount: "202944.0",
    },
    {
      date: "2022-12-08T07:00:00.000+03:00",
      sum_amount: "109969.0",
    },
    {
      date: "2022-12-08T06:00:00.000+03:00",
      sum_amount: "121650.0",
    },
    {
      date: "2022-12-08T05:00:00.000+03:00",
      sum_amount: "56362.0",
    },
    {
      date: "2022-12-08T04:00:00.000+03:00",
      sum_amount: "69565.0",
    },
    {
      date: "2022-12-08T03:00:00.000+03:00",
      sum_amount: "116883.0",
    },
    {
      date: "2022-12-08T02:00:00.000+03:00",
      sum_amount: "93371.0",
    },
    {
      date: "2022-12-08T01:00:00.000+03:00",
      sum_amount: "104975.0",
    },
    {
      date: "2022-12-08T00:00:00.000+03:00",
      sum_amount: "208089.0",
    },
    {
      date: "2022-12-07T23:00:00.000+03:00",
      sum_amount: "320455.0",
    },
    {
      date: "2022-12-07T22:00:00.000+03:00",
      sum_amount: "438182.0",
    },
    {
      date: "2022-12-07T21:00:00.000+03:00",
      sum_amount: "401437.0",
    },
    {
      date: "2022-12-07T20:00:00.000+03:00",
      sum_amount: "391680.0",
    },
    {
      date: "2022-12-07T19:00:00.000+03:00",
      sum_amount: "303460.0",
    },
    {
      date: "2022-12-07T18:00:00.000+03:00",
      sum_amount: "312607.0",
    },
    {
      date: "2022-12-07T17:00:00.000+03:00",
      sum_amount: "426893.0",
    },
    {
      date: "2022-12-07T16:00:00.000+03:00",
      sum_amount: "426875.0",
    },
  ],
  preview: [
    {
      date: "2022-12-07T15:00:00.000+03:00",
      sum_amount: "323796.0",
    },
    {
      date: "2022-12-07T14:00:00.000+03:00",
      sum_amount: "360482.0",
    },
    {
      date: "2022-12-07T13:00:00.000+03:00",
      sum_amount: "352752.0",
    },
    {
      date: "2022-12-07T12:00:00.000+03:00",
      sum_amount: "343820.0",
    },
    {
      date: "2022-12-07T11:00:00.000+03:00",
      sum_amount: "360996.0",
    },
    {
      date: "2022-12-07T10:00:00.000+03:00",
      sum_amount: "299681.0",
    },
    {
      date: "2022-12-07T09:00:00.000+03:00",
      sum_amount: "250725.0",
    },
    {
      date: "2022-12-07T08:00:00.000+03:00",
      sum_amount: "154398.0",
    },
    {
      date: "2022-12-07T07:00:00.000+03:00",
      sum_amount: "204052.0",
    },
    {
      date: "2022-12-07T06:00:00.000+03:00",
      sum_amount: "95056.0",
    },
    {
      date: "2022-12-07T05:00:00.000+03:00",
      sum_amount: "72009.0",
    },
    {
      date: "2022-12-07T04:00:00.000+03:00",
      sum_amount: "48117.0",
    },
    {
      date: "2022-12-07T03:00:00.000+03:00",
      sum_amount: "98237.0",
    },
    {
      date: "2022-12-07T02:00:00.000+03:00",
      sum_amount: "124441.0",
    },
    {
      date: "2022-12-07T01:00:00.000+03:00",
      sum_amount: "161287.0",
    },
    {
      date: "2022-12-07T00:00:00.000+03:00",
      sum_amount: "311168.0",
    },
    {
      date: "2022-12-06T23:00:00.000+03:00",
      sum_amount: "278235.0",
    },
    {
      date: "2022-12-06T22:00:00.000+03:00",
      sum_amount: "392887.0",
    },
    {
      date: "2022-12-06T21:00:00.000+03:00",
      sum_amount: "469433.0",
    },
    {
      date: "2022-12-06T20:00:00.000+03:00",
      sum_amount: "406639.0",
    },
    {
      date: "2022-12-06T19:00:00.000+03:00",
      sum_amount: "436152.0",
    },
    {
      date: "2022-12-06T18:00:00.000+03:00",
      sum_amount: "362084.0",
    },
    {
      date: "2022-12-06T17:00:00.000+03:00",
      sum_amount: "414318.0",
    },
    {
      date: "2022-12-06T16:00:00.000+03:00",
      sum_amount: "348345.0",
    },
  ],
};
