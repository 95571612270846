import React from "react";

import styles from "./styles.module.scss";

import DropDown, { IDropDown } from "../DropDown/DropDown";

interface IDropDownWithLabel extends IDropDown {
  label: string;
}

export default function DropDownWithLabel(props: IDropDownWithLabel) {
  const { label, ...rest } = props;
  return (
    <div className={styles.container}>
      <label className={styles.label}>{label}</label>
      <div className={styles.ddWrapper}>
        <DropDown {...rest} />
      </div>
    </div>
  );
}
