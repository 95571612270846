import React from "react";

import Card from "@/components/cards/Card/Card";
import { useTranslation } from "react-i18next";

import styles from "./styles.module.scss";
import SubHeading from "@/components/typography/SubHeading/SubHeading";

export default function DisputesStats() {
  const { t } = useTranslation();

  return (
    <>
      <SubHeading>{t("disputesPage.stats.title")}</SubHeading>
      <div className={styles.cards}>
        <div className={styles.card1}>
          <Card
            title={t("disputesPage.stats.pending.title") as string}
            body="₽ 13 349 999.00"
            footer={`2 938 ${t("disputesPage.stats.pending.footer")}`}
          />
        </div>
        <div className={styles.card2}>
          <Card
            type="danger"
            title={t("disputesPage.stats.checked.title") as string}
            body="₽ 45 312 245.21"
            footer={`2 938 ${t("disputesPage.stats.checked.footer")}`}
          />
        </div>
      </div>
    </>
  );
}
