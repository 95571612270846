import React from "react";

import { ITransactionListItem } from "@/store/transactions/transactions.types";
import { getTransactionTitle } from "@/utils/helpers/transactions";
import { StyleType } from "@/utils/types/shared";
import ModalIntro from "../../ModalBase/ModalIntro/ModalIntro";
import { useTranslation } from "react-i18next";

interface IModalIntroSwitcher {
  type: StyleType;
  transactionData: ITransactionListItem;
  setCreateDispute(state: boolean): void;
  isSuccessDispute?: boolean;
}

export default function ModalIntroSwitcher(props: IModalIntroSwitcher) {
  const { transactionData, type, setCreateDispute, isSuccessDispute } = props;
  const { t } = useTranslation();

  if (isSuccessDispute) {
    return (
      <ModalIntro
        title={t("modals.disputeCreated.title")}
        subTitle={t("modals.disputeCreated.subTitle")}
        type={type}
        transactionData={transactionData}
        setCreateDispute={setCreateDispute}
      />
    );
  }

  const title = getTransactionTitle({
    disputes: transactionData.disputes,
    short_type: transactionData.short_type,
    state: transactionData.state as "created",
  });

  function isDisputeAvailable() {
    if (transactionData?.disputes?.length === 0) {
      return transactionData.state === "failed";
    }
    if (transactionData.disputes && transactionData.state === "failed") {
      return (
        transactionData.disputes[transactionData.disputes?.length - 1].state ===
        "closed"
      );
    }
    return false;
  }

  return (
    <>
      <ModalIntro
        title={title}
        titleRight={transactionData.disputes?.length === 2 ? " x2" : ""}
        subTitle={transactionData.id}
        type={type}
        transactionData={transactionData}
        setCreateDispute={setCreateDispute}
        showShare={true}
        isDisputeAvailable={isDisputeAvailable()}
        popover={t("modals.transaction.hints.check") as string}
      />
    </>
  );
}
