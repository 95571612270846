import React from "react";

import styles from "./styles.module.scss";

interface IErrorBox {
  children: React.ReactNode;
  errorText?: string;
}

export default function ErrorBox(props: IErrorBox) {
  const { children, errorText } = props;
  return (
    <div className={styles.validateWrapper}>
      {children}
      <p className={styles.errorText}>{errorText}</p>
    </div>
  );
}
