import React from "react";

import styles from "./styles.module.scss";

export interface IModalCard {
  children: React.ReactNode;
  title?: string;
}

export default function ModalCard(props: IModalCard) {
  const { children, title } = props;
  return (
    <div className={styles.container}>
      {title && <h6 className={styles.title}>{title}</h6>}
      <div className={styles.content}>{children}</div>
    </div>
  );
}
