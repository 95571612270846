import React from "react";

import styles from "./styles.module.scss";

import { IStyledComponent, SizeType } from "@/utils/types/shared";
import classNames from "classnames";

interface IBadge extends IStyledComponent {
  size?: SizeType;
}

export default function Badge(props: IBadge) {
  const { children, type, size } = props;
  return (
    <div
      className={classNames(styles.container, {
        [styles.accent]: type === "accent",
        [styles.primary]: type === "primary",
        [styles.success]: type === "success",
        [styles.danger]: type === "danger",
        [styles.warning]: type === "warning",
        [styles.successLight]: type === "successLight",

        [styles.sm]: size === "sm",
        [styles.md]: size === "md",
        [styles.lg]: size === "lg",
      })}
    >
      {children}
    </div>
  );
}
