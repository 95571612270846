const monthLabel = [
  "января",
  "февраля",
  "марта",
  "апреля",
  "мая",
  "июня",
  "июля",
  "августа",
  "сентября",
  "октября",
  "ноября",
  "декабря",
];

function changeTime(timeData: number) {
  const time = timeData.toString();
  return time.length === 1 ? "0" + time : time;
}

function parseTime(date: any, withSeconds: boolean = false) {
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();
  return `${changeTime(hours)}:${changeTime(minutes)}${
    withSeconds ? `:${changeTime(seconds)}` : ""
  }`;
}
type parseMode = "default" | "timeOnly";

export function parseDate(
  dateData: string | Date,
  mode: parseMode = "default",
  withSeconds: boolean = false
) {
  const now = new Date();
  const date = new Date(dateData);

  const dateInHours = date.getTime() / 1000 / 60 / 60 / 24;
  const yesterday = now.getTime() / 1000 / 60 / 60 / 24 - 1;
  const dayBeforeYesterday = now.getTime() / 1000 / 60 / 60 / 24 - 2;

  const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDate();

  if (mode === "timeOnly") {
    return parseTime(date, withSeconds);
  }
  if (mode === "default" && dateInHours > yesterday) {
    return `Сегодня в ${parseTime(date, withSeconds)}`;
  }
  if (mode === "default" && dateInHours > dayBeforeYesterday) {
    return `Вчера в ${parseTime(date, withSeconds)}`;
  }
  return `${day} ${monthLabel[month]} ${year} г., в ${parseTime(
    date,
    withSeconds
  )}`;
}
