import React, { useState } from "react";

import styles from "./styles.module.scss";

import { ReactComponent as CopiedIcon } from "@assets/icons/copy.svg";
import { ReactComponent as NotCopiedIcon } from "@assets/icons/copy-uncoped.svg";
import ModalCard, { IModalCard } from "@/components/cards/ModalCard/ModalCard";
import classNames from "classnames";
import useCopyToClipboard from "@/utils/hooks/useCopyToClipboard";

interface IModalCardData extends IModalCard {
  cardIcon?: React.ReactElement;
}

export default function ModalCardData(props: IModalCardData) {
  const { children, title, cardIcon } = props;
  const [isCopied, setIsCopied] = useState(false);

  const [value, copy] = useCopyToClipboard();

  async function copyToClipboard() {
    const isSuccess = await copy(children as string);
    isSuccess === true && setIsCopied(true);
  }

  return (
    <ModalCard title={title}>
      <div
        onClick={copyToClipboard}
        className={classNames(styles.wrapper, { [styles.withIcon]: cardIcon })}
      >
        {cardIcon && <span className={styles.cardIcon}>{cardIcon}</span>}
        {children}
        {isCopied ? (
          <CopiedIcon className={styles.copyIcon} />
        ) : (
          <NotCopiedIcon
            className={classNames(styles.copyIcon, styles.notCopied)}
          />
        )}
      </div>
    </ModalCard>
  );
}
