import React, { useMemo, useState } from "react";

import styles from "../FilterByAllTransactions/styles.module.scss";

import DatePickerComponent from "@/components/forms/DatePicker/DatePicker";
import Input from "@/components/forms/Input/Input";
import InputWithLabel from "@/components/forms/InputWithLabel/InputWithLabel";
import DropDownWithLabel from "@/components/controls/DropDownWithLabel/DropDownWithLabel";
import FilterBase from "../FilterBase/FilterBase";
import { useTranslation } from "react-i18next";
import {
  IEquiringFields,
  ITransactionsFilter,
} from "@/utils/types/filterTransactions";
import { initialAllState } from "../FilterByAllTransactions/FilterByAllTransactions";
import getTitle from "@/utils/helpers/filterTitle";
import validateCardNumber from "@/utils/helpers/validateCardNumber";

export const initialAcquiringState: IEquiringFields = {
  ...initialAllState,
  payerCardNumber: "",
  payerCardBrand: "",
  payerCardBankAlias: "",
};

export default function FilterByAcquiringTransactions(
  props: ITransactionsFilter
) {
  const { titlePlaceholder, onSubmit } = props;

  const [state, setState] = useState(initialAcquiringState);
  const { t } = useTranslation();

  function onFieldChange(value: string | Date, fieldKey: string) {
    setState((currentState) => ({ ...currentState, [fieldKey]: value }));
  }

  function filterSubmit() {
    onSubmit(state);
  }

  function clearState() {
    setState(initialAcquiringState);
    onSubmit(initialAcquiringState);
  }

  const title = useMemo(() => getTitle(state), [state]);

  return (
    <FilterBase
      titlePlaceholder={titlePlaceholder}
      title={title}
      onSubmit={filterSubmit}
      onCancel={clearState}
    >
      <div className={styles.container}>
        <div className={styles.filterContainer}>
          <InputWithLabel
            label={t("transactionsPage.searchParams.sum.from.title")}
            size="sm"
            mode="light"
            inputAttributes={{
              type: "number",
              name: "amountGt",
              value: state.amountGt,
              onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                onFieldChange(e.target.value, "amountGt"),
              placeholder: t(
                "transactionsPage.searchParams.sum.from.placeHolder"
              ).toString(),
            }}
            rightElement={<span>₽</span>}
          />
          <Input
            size="sm"
            mode="light"
            inputAttributes={{
              type: "number",
              name: "amountLt",
              value: state.amountLt,
              onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                onFieldChange(e.target.value, "amountLt"),
              placeholder: t(
                "transactionsPage.searchParams.sum.to.placeHolder"
              ).toString(),
            }}
            rightElement={<span>₽</span>}
          />
          <DropDownWithLabel
            items={["Рубль(₽)", "Тенге(T)"]}
            mode="outline"
            label={t("transactionsPage.searchParams.sum.currency.title")}
          />
        </div>
        <div className={styles.dates}>
          <div>
            <DatePickerComponent
              label={
                t("transactionsPage.searchParams.date.from.title") as string
              }
              onChange={(date: Date | string) => onFieldChange(date, "dateGt")}
              placeholder={
                t(
                  "transactionsPage.searchParams.sum.from.placeHolder"
                ) as string
              }
              selected={state.dateGt}
            />
          </div>
          <div>
            <DatePickerComponent
              placeholder={
                t("transactionsPage.searchParams.sum.to.placeHolder") as string
              }
              onChange={(date: Date | string) => onFieldChange(date, "dateLt")}
              selected={state.dateLt}
            />
          </div>
        </div>
        <div className={styles.cardData}>
          <InputWithLabel
            size="sm"
            mode="light"
            label={
              t(
                "transactionsPage.searchParams.acquiring.cardNumber.title"
              ) as string
            }
            inputAttributes={{
              type: "text",
              name: "payerCardNumber",
              value: state.payerCardNumber,
              onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                validateCardNumber(
                  e,
                  (card: string) => onFieldChange(card, "payerCardNumber"),
                  state.payerCardNumber
                ),
              placeholder: t(
                "transactionsPage.searchParams.acquiring.cardNumber.placeholder"
              ) as string,
            }}
          />
          <DropDownWithLabel
            items={[
              t(
                "transactionsPage.searchParams.acquiring.paySystem.placeholder"
              ) as string,
              "МИР",
              "Visa",
              "Mastercard",
              "Maestro",
              "Maestro2",
              "Maestro3",
            ]}
            selected={state.payerCardBrand}
            onChange={(value: string) => onFieldChange(value, "payerCardBrand")}
            mode="outline"
            label={t("transactionsPage.searchParams.acquiring.paySystem.title")}
          />
        </div>
        <div className={styles.block}>
          <DropDownWithLabel
            items={[
              t(
                "transactionsPage.searchParams.acquiring.bank.placeholder"
              ) as string,
              "Сбербанк RU",
              "Тинькофф RU",
              "Райффазен банк RU",
              "Giga Chad BANK US",
              "Giga Chad BANK RU",
            ]}
            selected={state.payerCardBankAlias}
            onChange={(value: string) =>
              onFieldChange(value, "payerCardBankAlias")
            }
            mode="outline"
            label={t("transactionsPage.searchParams.acquiring.bank.title")}
          />
        </div>
      </div>
    </FilterBase>
  );
}
