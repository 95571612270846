import React, { useState } from "react";

import styles from "./styles.module.scss";

import i18n from "i18next";

import { ReactComponent as UsersPlus } from "@assets/icons/users-plus.svg";
import { useNavigate } from "react-router-dom";
import ModalBase from "../ModalBase/ModalBase";
import ModalHead from "../ModalBase/ModalHead/ModalHead";
import ModalContent from "../ModalBase/ModalContent/ModalContent";
import ModalPayoutCreate from "./ModalPayoutCreate/ModalPayoutCreate";
import ModalPayoutSuccess from "./ModalPayoutSuccess/ModalPayoutSuccess";

export const accounts = [
  {
    accountTitle: "Счет №1",
    currency: "RUB",
    commission: "1,5%",
    id: "#MP002N2561663719",
  },
  {
    accountTitle: "Счет №2",
    currency: "RUB",
    commission: "2%",
    id: "#MP002N2561663729",
  },
  {
    accountTitle: "Счет №3",
    currency: "KZT",
    commission: "1,5%",
    id: "#MP002N2561663739",
  },
  {
    accountTitle: "Счет №4",
    currency: "UZB",
    commission: "3%",
    id: "#MP002N2561663749",
  },
  {
    accountTitle: "Счет №5",
    currency: "USD",
    commission: "0,5%",
    id: "#MP002N2561663759",
  },
  {
    accountTitle: "Счет №6",
    currency: "USD",
    commission: "0,5%",
    id: "#MP002N2561663769",
  },
  {
    accountTitle: "Счет №7",
    currency: "USD",
    commission: "0,5%",
    id: "#MP002N2561663779",
  },
];

export const amountMinValue = 1000;
export interface IClientItem {
  clientId: string;
  paymentMethod: string;
  cardNumber: string;
  amount: string | number;
  errors: any;
}
export const initialAccountItem: IClientItem = {
  clientId: "",
  paymentMethod: i18n.t("modals.payout.client.dd.card"),
  cardNumber: "",
  amount: "",
  errors: {},
};

const type = "accent";
export default function ModalPayout() {
  const [clientsData, setClientsData] = useState([initialAccountItem]);
  const [selected, setSelected] = useState<any>(null);
  const [successVisible, setSuccessVisible] = useState(false);
  const navigate = useNavigate();

  function closeModal() {
    navigate(-1);
  }

  return (
    <ModalBase style={successVisible ? { maxWidth: 420 } : undefined}>
      <ModalHead
        type={type}
        closeModal={closeModal}
        avatarIcon={<UsersPlus />}
        text={successVisible ? new Date().toDateString() : undefined}
      />
      <ModalContent>
        {successVisible ? (
          <ModalPayoutSuccess
            type={type}
            selected={selected}
            clients={clientsData}
          />
        ) : (
          <ModalPayoutCreate
            setSuccessVisible={setSuccessVisible}
            clientsData={clientsData}
            setClientsData={setClientsData}
            selected={selected}
            setSelected={setSelected}
          />
        )}
      </ModalContent>
    </ModalBase>
  );
}
