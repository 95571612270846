import AuthForm from "@/components/forms/AuthForm/AuthForm";
import AppLogo from "@/components/shared/AppLogo/AppLogo";
import React from "react";

import styles from "./styles.module.scss";

export default function Auth() {
  return (
    <div className={styles.page}>
      <AppLogo />
      <AuthForm />
    </div>
  );
}
