import InputWithLabel from "@/components/forms/InputWithLabel/InputWithLabel";
import ControlBox from "@/components/controls/ControlBox/ControlBox";
import DropDown from "@/components/controls/DropDown/DropDown";
import ErrorBox from "@/components/shared/ErrorBox/ErrorBox";
import validateCardNumber from "@/utils/helpers/validateCardNumber";
import classNames from "classnames";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { amountMinValue, IClientItem } from "../ModalPayout";

import styles from "./styles.module.scss";

type setClientsData = React.Dispatch<React.SetStateAction<IClientItem[]>>;
interface IModalClient {
  index: number;
  setClientsData: setClientsData;
  account: IClientItem;
  title: string;
  active: boolean;
  errorsVisible: boolean;
}

export default function ModalClient(props: IModalClient) {
  const { active, title, account, errorsVisible, index, setClientsData } =
    props;

  const [isDdMuted, setIsDdMuted] = useState(true);
  const { t } = useTranslation();

  function onFieldChange(val: any, field: string, ddIndex?: number) {
    if (field === "paymentMethod") {
      setIsDdMuted(ddIndex === 0 ? true : false);
    }
    setClientsData((currentState) => {
      let cur = [...currentState];
      cur[index] = { ...cur[index], [field]: val };
      return cur;
    });
  }

  function getFieldType(field: string) {
    if (errorsVisible && account.errors?.[field]) {
      return "error";
    }
    return active ? undefined : "empty";
  }

  return (
    <div className={styles.client}>
      <h4 className={styles.title}>{title}</h4>
      <InputWithLabel
        borderType="md"
        label={`ID ${t("modals.payout.client.id")}`}
        inputAttributes={{
          onChange: (e: any) => onFieldChange(e.target.value, "clientId"),
          value: account.clientId,
          placeholder: "#",
        }}
        type={getFieldType("clientId")}
        mode={!active ? undefined : "light"}
        error={
          errorsVisible && account.errors?.clientId && account.errors.clientId
        }
      />
      <div
        className={classNames(styles.dd, {
          [styles.muted]: !active,
        })}
      >
        <ErrorBox
          errorText={
            errorsVisible && account.errors?.paymentMethod
              ? account.errors.paymentMethod
              : ""
          }
        >
          <ControlBox
            type={getFieldType("paymentMethod")}
            title={`${t("modals.payout.client.dd.title")} *`}
          >
            <DropDown
              selected={account.paymentMethod}
              onChange={(value, index) =>
                onFieldChange(value, "paymentMethod", index)
              }
              items={[
                t("modals.payout.client.dd.card"),
                // t("modals.payout.client.dd.none"),
              ]}
              mode={"heightFull"}
            />
          </ControlBox>
        </ErrorBox>
      </div>
      <InputWithLabel
        borderType="md"
        label={`${t("modals.payout.client.number.title")} *`}
        inputAttributes={{
          onChange: (e: any) =>
            validateCardNumber(
              e,
              (card: string) => onFieldChange(card, "cardNumber"),
              account.cardNumber
            ),
          value: account.cardNumber,
          placeholder: `16 ${t("modals.payout.client.number.placeholder")}`,
        }}
        type={getFieldType("cardNumber")}
        mode={!active ? undefined : "light"}
        error={
          errorsVisible &&
          account.errors?.cardNumber &&
          account.errors.cardNumber
        }
      />
      <InputWithLabel
        borderType="md"
        label={`${t("modals.payout.client.amount.title")} *`}
        inputAttributes={{
          onChange: (e: any) => onFieldChange(e.target.value, "amount"),
          placeholder: `${t(
            "modals.payout.client.amount.placeholder.left"
          )} ${amountMinValue} ${t(
            "modals.payout.client.amount.placeholder.right"
          )}`,
          value: account.amount,
          type: "number",
        }}
        rightElement={<span>₽</span>}
        type={getFieldType("amount")}
        mode={!active ? undefined : "light"}
        error={errorsVisible && account.errors?.amount && account.errors.amount}
      />
    </div>
  );
}
