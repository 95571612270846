import React from "react";

import styles from "./styles.module.scss";
import sharedStyles from "@utils/styles/sharedStyles.module.scss";

import classNames from "classnames";
import CalculatedValue from "../../shared/CalculatedValue/CalculatedValue";

interface ICard {
  title: string;
  body: string;
  ellipse: React.ReactElement;
}

export default function CardWithEllipse(props: ICard) {
  const { title, body, ellipse } = props;
  return (
    <div className={classNames(sharedStyles.cardContainer, styles.container)}>
      <div className={styles.ellipse}>{ellipse}</div>
      <div className={styles.content}>
        <div className={styles.header}>
          <h4>{title}</h4>
        </div>
        <CalculatedValue value={body} />
      </div>
    </div>
  );
}
