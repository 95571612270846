import { ITransactionListItem } from "@/store/transactions/transactions.types";
import i18n from "i18next";
import { transactionStatusStyle } from "../constants/transactionStatusType";
import { StyleType } from "../types/shared";

export function getTransactionTitle(
  item: Pick<ITransactionListItem, "disputes" | "state" | "short_type">
) {
  if (item.disputes.length > 0) {
    return i18n.t(
      `transactionStatusText.disputes.${
        item.disputes[item.disputes.length - 1].state
      }.title`
    );
  }
  return i18n.t(`transactionStatusText.${item.short_type}.${item.state}.title`);
}

export function getChipText(item: ITransactionListItem) {
  if (item.disputes.length > 0) {
    return i18n.t(
      `transactionStatusText.disputes.${
        item.disputes[item.disputes.length - 1].state
      }.statusText`
    );
  }
  return i18n.t(
    `transactionStatusText.${item.short_type}.${item.state}.statusText`
  );
}

export function getTransactionType(item: ITransactionListItem) {
  if (item.disputes.length > 0) {
    return transactionStatusStyle.disputes[
      item.disputes[item.disputes.length - 1].state as "closed"
    ].styleType as StyleType;
  }
  return transactionStatusStyle[item.short_type as "p2p"][item.state]
    .styleType as StyleType;
}
