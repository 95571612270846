import { SizeType, StyleType } from "@/utils/types/shared";
import classNames from "classnames";
import React from "react";
import Badge from "../Badge/Badge";

import styles from "./styles.module.scss";
interface IAvatar {
  mode?: "square" | "squareOutline" | "bgDark" | "circle";
  children: React.ReactNode;
  type?: StyleType;
  size?: SizeType;
  badge?: string | React.ReactNode | null;
}

export default function Avatar(props: IAvatar) {
  const { type = "accent", mode = "circle", children, size, badge } = props;
  return (
    <div
      className={classNames(styles.container, {
        [styles.loading]: type === "loading",
        [styles.accent]: type === "accent",
        [styles.primary]: type === "primary",
        [styles.success]: type === "success",
        [styles.danger]: type === "danger",
        [styles.warning]: type === "warning",
        [styles.successLight]: type === "successLight",

        [styles.square]: mode === "square" || mode === "squareOutline",
        [styles.squareOutline]: mode === "squareOutline",
        [styles.bgDark]: mode === "bgDark",

        [styles.sm]: size === "sm",
        [styles.md]: size === "md",
        [styles.lg]: size === "lg",
      })}
    >
      {children}
      {badge && (
        <div className={styles.badge}>
          <Badge type={type} size={size}>
            {badge}
          </Badge>
        </div>
      )}
    </div>
  );
}
