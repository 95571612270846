import React from "react";

import styles from "../HorizontalChart/styles.module.scss";
import sharedStyles from "@utils/styles/sharedStyles.module.scss";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import {
  abbreviateNumber,
  createBarChart,
  getMaxChartY,
} from "@/utils/helpers/chart";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import DropDown from "@/components/controls/DropDown/DropDown";
import { parseDate } from "@/utils/helpers/parseDate";

const graphResponse = {
  current: [
    {
      date: "2023-01-10T15:00:00.000+03:00",
      sum_amount: "349996.0",
    },
    {
      date: "2023-01-10T14:00:00.000+03:00",
      sum_amount: "469037.0",
    },
    {
      date: "2023-01-10T13:00:00.000+03:00",
      sum_amount: "352111.0",
    },
    {
      date: "2023-01-10T12:00:00.000+03:00",
      sum_amount: "369701.0",
    },
    {
      date: "2023-01-10T11:00:00.000+03:00",
      sum_amount: "370708.0",
    },
    {
      date: "2023-01-10T10:00:00.000+03:00",
      sum_amount: "292699.0",
    },
    {
      date: "2023-01-10T09:00:00.000+03:00",
      sum_amount: "229209.0",
    },
    {
      date: "2023-01-10T08:00:00.000+03:00",
      sum_amount: "202944.0",
    },
    {
      date: "2023-01-10T07:00:00.000+03:00",
      sum_amount: "109969.0",
    },
    {
      date: "2023-01-10T06:00:00.000+03:00",
      sum_amount: "121650.0",
    },
  ],
  preview: [
    {
      date: "2023-01-10T15:00:00.000+03:00",
      sum_amount: "249996.0",
    },
    {
      date: "2023-01-10T14:00:00.000+03:00",
      sum_amount: "369037.0",
    },
    {
      date: "2023-01-10T13:00:00.000+03:00",
      sum_amount: "552111.0",
    },
    {
      date: "2023-01-10T12:00:00.000+03:00",
      sum_amount: "169701.0",
    },
    {
      date: "2023-01-10T11:00:00.000+03:00",
      sum_amount: "300708.0",
    },
    {
      date: "2023-01-10T10:00:00.000+03:00",
      sum_amount: "212699.0",
    },
    {
      date: "2023-01-10T09:00:00.000+03:00",
      sum_amount: "129209.0",
    },
    {
      date: "2023-01-10T08:00:00.000+03:00",
      sum_amount: "292944.0",
    },
    {
      date: "2023-01-10T07:00:00.000+03:00",
      sum_amount: "169969.0",
    },
    {
      date: "2023-01-10T06:00:00.000+03:00",
      sum_amount: "111650.0",
    },
  ],
};

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

let cursym = "₽";
let ffamily = "'Inter', sans-serif";

const options: any = {
  layout: {
    padding: {
      top: 35,
    },
  },

  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    title: {
      display: false,
    },
    legend: {
      display: false,
    },
    tooltip: {
      intersect: false,
      mode: "index",
      backgroundColor: "#ffffff",
      position: "nearest",
      displayColors: false,
      caretSize: 0,
      caretPadding: 10,
      borderColor: "#EEF2F9",
      borderWidth: 1,
      padding: {
        top: 12,
        right: 40,
        bottom: 12,
        left: 12,
      },
      cornerRadius: 8,

      titleColor: "#2787F5",
      titleMarginBottom: 0,
      titleFont: {
        family: ffamily,
        weight: 600,
        size: 16,
      },

      footerColor: "#FA6C6C",
      footerFont: {
        family: ffamily,
        weight: 600,
        size: 12,
      },

      bodyFont: {
        size: 0,
      },

      callbacks: {
        title: (context: any) => {
          const { formattedValue } = context[0];
          return `${cursym} ${formattedValue}`;
        },
        afterTitle: (context: any) => {
          const { label } = context[0];
          // return moment(label).locale("ru").calendar();
          return parseDate(label);
        },
        footer: (context: any) => {
          const { formattedValue } = context[1];
          return `${cursym} ${formattedValue}`;
        },
        afterFooter: (context: any) => {
          const { label } = context[1];
          // return moment(label).locale("ru").calendar();
          return parseDate(label);
        },
      },
    },
  },
  scales: {
    x: {
      display: true,
      min: 0,
      max: graphResponse.current.length,
      grid: { display: false, drawBorder: false },
      border: { color: "#F3F5F8" },
      ticks: {
        type: "time",
        color: "#123E70",
        font: {
          family: ffamily,
          weight: 400,
        },
        callback: (val: number) => {
          const date = graphResponse.current[val].date;
          // return moment(date).format("HH:mm");
          return parseDate(date, "timeOnly");
        },
      },
    },

    y: {
      max: Math.ceil(
        getMaxChartY(graphResponse.current, graphResponse.preview, 1.1)
      ),
      min: 0,
      display: true,
      border: {
        display: false,
        dash: [5, 3],
      },
      grid: {
        color: "#F3F5F8",
        lineWidth: 1,
        drawOnChartArea: true,
        drawTicks: false,
        circular: true,
        offset: true,
      },
      ticks: {
        color: "#123E70",
        font: {
          family: ffamily,
          weight: 500,
        },
        callback: (val: number) => {
          return abbreviateNumber(val);
        },
      },
    },
  },
  elements: {
    point: {
      radius: 0,
      borderWidth: 1,
      hoverRadius: 6,
    },
  },
  hover: {
    mode: "index",
    intersect: false,
  },
};

export const data = {
  labels: graphResponse.current.map((el) => el.date).slice(0, 11),
  categoryPercentage: 0.5,
  datasets: [
    createBarChart(graphResponse.current, "#2787F5"),
    createBarChart(graphResponse.preview, "#FA6C6C"),
  ],
};

export function VerticalChart() {
  const { t } = useTranslation();

  return (
    <div className={classNames(sharedStyles.cardContainer, styles.container)}>
      <div className={styles.heading}>
        <h3 className={styles.title}>{t("transactionsPage.chart.title")}</h3>
        <span className={styles.dropDown}>
          <DropDown
            items={[
              t("mainPage.stats.chart.dd.day"),
              t("mainPage.stats.chart.dd.month"),
              t("mainPage.stats.chart.dd.year"),
            ]}
          />
        </span>
      </div>
      <div className={styles.canvas}>
        <Bar options={options} data={data} />
      </div>
    </div>
  );
}
