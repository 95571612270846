import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import ru from "./ru";

import en from "./en/translation.json";

export const resources = {
  ru: {
    translation: ru,
  },
  en: {
    translation: en,
  },
};

i18next.use(initReactI18next).init({
  lng: "ru", // if you're using a language detector, do not define the lng option
  debug: true,
  resources,
});
