import React, { useRef, useState } from "react";

import styles from "./styles.module.scss";

import useOnClickOutside from "@/utils/hooks/useClickOutside";
import { ReactComponent as HelpIcon } from "@assets/icons/help-circle.svg";
import classNames from "classnames";

interface IPopover {
  children: React.ReactNode;
  title?: string;
  count?: string;
  mode?: "inline";
}

export default function Popover(props: IPopover) {
  const { children, title, count, mode } = props;
  const [isPopoverVisible, setPopoverVisible] = useState(false);

  const popoverRef = useRef<HTMLParagraphElement | null>(null);
  const helpControlRef = useRef<HTMLButtonElement | null>(null);
  useOnClickOutside(popoverRef, closePopover, helpControlRef);

  function togglePopover() {
    setPopoverVisible((current) => !current);
  }
  function closePopover() {
    setPopoverVisible(false);
  }
  return (
    <div className={styles.title}>
      {title && title}
      {count && <span className={styles.count}> {count}</span>}
      {children && (
        <span className={styles.help}>
          <button
            ref={helpControlRef}
            onClick={togglePopover}
            className={classNames(styles.helpControl, {
              [styles.active]: isPopoverVisible,
            })}
          >
            <HelpIcon />
            {mode === "inline" && (
              <p
                ref={popoverRef}
                className={classNames(styles.popover, {
                  [styles.visible]: isPopoverVisible,
                })}
              >
                {children}
              </p>
            )}
          </button>
          {mode !== "inline" && (
            <p
              ref={popoverRef}
              className={classNames(styles.popover, {
                [styles.visible]: isPopoverVisible,
              })}
            >
              {children}
            </p>
          )}
        </span>
      )}
    </div>
  );
}
