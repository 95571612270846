import { ITransactionListParams } from "@/store/transactions/transactions.api";

export default function addTransactionsQueryParams(
  params: ITransactionListParams
) {
  return `${
    params.disputes ? `&q[dispute_count_gteq]=${params.disputes}` : ""
  }${
    params.state ? params.state?.map((i) => "&q[state_in][]=" + i).join("") : ""
  }${params.type ? "&q[type_eq]=" + params.type : ""}${
    params.externalId ? "&q[external_id_eq]=" + params.externalId : ""
  }${params.amountGt ? "&q[amount_gt]=" + params.amountGt : ""}${
    params.amountLt ? "&q[amount_lt]=" + params.amountLt : ""
  }${params.currency ? "&q[currency_eq]=" + params.currency : ""}
 ${params.dateGt ? "&q[updated_at_gt]=" + params.dateGt : ""}${
    params.dateLt ? "&q[updated_at_lt]=" + params.dateLt : ""
  }${
    params.payerCardNumber
      ? "&q[payer_card_number_matches]=" + params.payerCardNumber + "%25"
      : ""
  }${
    params.payerCardBrand
      ? "&q[payer_card_brand_eq]=" + params.payerCardBrand
      : ""
  }${
    params.payerCardBankAlias
      ? "&q[payer_card_bank_issuer_eq]=" + params.payerCardBankAlias
      : ""
  }${
    params.sellerCardNumber
      ? "&q[material_card_number_matches]=" + params.sellerCardNumber + "%25"
      : ""
  }${
    params.sellerCardBrand
      ? "&q[material_card_brand_eq]=" + params.sellerCardBrand
      : ""
  }
  ${
    params.sellerCardBankAlias
      ? "&q[material_card_bank_issuer_alias_eq]=" + params.sellerCardBankAlias
      : ""
  }
  `;
}
