import React from "react";

import styles from "./styles.module.scss";

export default function ModalContent({
  children,
}: {
  children: React.ReactNode;
}) {
  return <div className={styles.body}>{children}</div>;
}
