export const transactionStatusStyle = {
  disputes: {
    created: {
      // title: "Спор создан",
      // statusText: "Создан",
      styleType: "accent",
    },
    opened: {
      // title: "Оспаривается",
      // statusText: "Оспаривается",
      styleType: "warning",
    },
    accepted: {
      // title: "Спор подтвержден",
      // statusText: "Подтвержден",
      styleType: "successLight",
    },
    closed: {
      // title: "Спор  отклонен",
      // statusText: "Отклонен",
      styleType: "danger",
    },
  },
  c2c: {
    created: {
      // title: "Транзакция создана",
      // statusText: "Создана",
      styleType: "accent",
    },
    pending: {
      // title: "Транзакция в обработке",
      // statusText: "Обработка",
      styleType: "warning",
    },
    confirmed: {
      // title: "Транзакция выполнена",
      // statusText: "Выполнена",
      styleType: "successLight",
    },
    failed: {
      // title: "Транзакция отклонена",
      // statusText: "Отклонена",
      styleType: "danger",
    },
  },
  p2p: {
    created: {
      // title: "Платеж зачислен",
      // statusText: "Зачислен",
      styleType: "accent",
    },
    pending: {
      // title: "Ожидание оплаты",
      // statusText: "Ожидание",
      styleType: "success",
    },
    confirmed: {
      // title: "Платеж зачислен",
      // statusText: "Зачислен",
      styleType: "successLight",
    },
    canceled: {
      // title: "Платеж отменен",
      // statusText: "Отменен",
      styleType: "danger",
    },
    failed: {
      // title: "Истекло время оплаты",
      // statusText: "Истекло время",
      styleType: "danger",
    },
  },
};
