import React from "react";

import styles from "./styles.module.scss";

import SubHeading from "@/components/typography/SubHeading/SubHeading";
import { ReactComponent as InfoIcon } from "@assets/icons/info-circle.svg";
import { ReactComponent as ArrowIcon } from "@assets/icons/chevron-right.svg";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import CardWithNavigation from "@/components/cards/CardWithNavigation/CardWithNavigation";
import { tr } from "date-fns/locale";
import Chip from "@/components/shared/Chip/Chip";

export default function MassPayoutsAccounts() {
  const { t } = useTranslation();

  const navigationPrevRef = React.useRef(null);
  const navigationNextRef = React.useRef(null);

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>
        <SubHeading
          rightElement={
            <div className={styles.accountsActive}>
              <span>{t("payoutsPage.accounts.count")} </span>
              <strong className={styles.count}>5 из 10</strong>
            </div>
          }
        >
          {t("payoutsPage.accounts.title")}
        </SubHeading>
      </div>
      <div className={styles.listContainer}>
        <div className={styles.prev} ref={navigationPrevRef}>
          <ArrowIcon />
        </div>
        <div className={styles.next} ref={navigationNextRef}>
          <ArrowIcon />
        </div>
        <Swiper
          modules={[Navigation]}
          className={styles.list}
          slidesPerView={3}
          navigation={true}
          spaceBetween={20}
          grabCursor={true}
          //   @ts-ignore
          navigation={{
            prevEl: navigationPrevRef.current,
            nextEl: navigationNextRef.current,
          }}
          onBeforeInit={(swiper: any) => {
            swiper.params.navigation.prevEl = navigationPrevRef.current;
            swiper.params.navigation.nextEl = navigationNextRef.current;
          }}
        >
          <SwiperSlide>
            <CardWithNavigation
              link="/"
              title={
                <div className={styles.accountTitle}>
                  <Chip mode="currency" type="primary">
                    RUB
                  </Chip>
                  <span className={styles.number}>Счёт №2</span>
                </div>
              }
              titleElement={<InfoIcon />}
              subTitle={"#MP002N2561663719"}
              body={"₽ 13 349 999.00"}
              bodyLeftSign={"≈"}
              bodyPercent={"1,5%"}
              footer={"1 231 выплат за все время"}
              footerElement={
                <Chip mode="state" type="accent">
                  Вкл
                </Chip>
              }
            />
          </SwiperSlide>
          <SwiperSlide>
            <CardWithNavigation
              link="/"
              title={
                <div className={styles.accountTitle}>
                  <Chip mode="currency" type="primary">
                    RUB
                  </Chip>
                  <span className={styles.number}>Счёт №2</span>
                </div>
              }
              titleElement={<InfoIcon />}
              subTitle={"#MP002N2561663719"}
              body={"₽ 13 349 999.00"}
              bodyLeftSign={"≈"}
              bodyPercent={"1,5%"}
              footer={"1 231 выплат за все время"}
              footerElement={
                <Chip mode="state" type="accent">
                  Вкл
                </Chip>
              }
            />
          </SwiperSlide>
          <SwiperSlide>
            <CardWithNavigation
              link="/"
              title={
                <div className={styles.accountTitle}>
                  <Chip mode="currency" type="primary">
                    RUB
                  </Chip>
                  <span className={styles.number}>Счёт №2</span>
                </div>
              }
              titleElement={<InfoIcon />}
              subTitle={"#MP002N2561663719"}
              body={"₽ 13 349 999.00"}
              bodyLeftSign={"≈"}
              bodyPercent={"1,5%"}
              footer={"1 231 выплат за все время"}
              footerElement={
                <Chip mode="state" type="accent">
                  Вкл
                </Chip>
              }
            />
          </SwiperSlide>
          <SwiperSlide>
            <CardWithNavigation
              link="/"
              title={
                <div className={styles.accountTitle}>
                  <Chip mode="currency" type="primary">
                    RUB
                  </Chip>
                  <span className={styles.number}>Счёт №2</span>
                </div>
              }
              titleElement={<InfoIcon />}
              subTitle={"#MP002N2561663719"}
              body={"₽ 13 349 999.00"}
              bodyLeftSign={"≈"}
              bodyPercent={"1,5%"}
              footer={"1 231 выплат за все время"}
              footerElement={
                <Chip mode="state" type="accent">
                  Вкл
                </Chip>
              }
            />
          </SwiperSlide>
          <SwiperSlide>
            <CardWithNavigation
              link="/"
              title={
                <div className={styles.accountTitle}>
                  <Chip mode="currency" type="primary">
                    RUB
                  </Chip>
                  <span className={styles.number}>Счёт №2</span>
                </div>
              }
              titleElement={<InfoIcon />}
              subTitle={"#MP002N2561663719"}
              body={"₽ 13 349 999.00"}
              bodyLeftSign={"≈"}
              bodyPercent={"1,5%"}
              footer={"1 231 выплат за все время"}
              footerElement={
                <Chip mode="state" type="accent">
                  Вкл
                </Chip>
              }
            />
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
}
