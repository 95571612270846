import React, { useState, useEffect } from "react";

import styles from "./styles.module.scss";
import sharedStyles from "@utils/styles/sharedStyles.module.scss";

import SubHeading from "@/components/typography/SubHeading/SubHeading";
import FilterByAllTransactions from "@/components/filters/FilterByAllTransactions/FilterByAllTransactions";
import TransactionsList from "@/components/transactions/TransactionsList/TransactionsList";
import FilterByStatus from "@/components/filters/FilterByStatus/FilterByStatus";
import FilterByAcquiringTransactions from "@/components/filters/FilterByAcquiringTransactions copy/FilterByAcquiringTransactions ";
import FilterSearch from "@/components/filters/FilterSearch/FilterSearch";
import ModalComponent from "@/components/modals/Modal/Modal";
import DisputesStats from "./DisputesStats/DisputesStats";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  IAllTransactionsFields,
  IEquiringFields,
} from "@/utils/types/filterTransactions";
import FilterByP2PTransactions, {
  initialP2PState,
} from "@/components/filters/FilterByP2PTransactions/FilterByP2PTransactions";
import TransactionsStats from "./TransactionsStats/TransactionsStats";
import TransactionsHead from "./TransactionsHead/TransactionsHead";

export default function Transactions() {
  const [id, setId] = useState<null | string>(null);
  const [externalId, setExternalId] = useState<string | null>(null);
  const [stateQueryParameters, setStateQueryParameters] = useState<string[]>(
    []
  );
  const [filterQueries, setFilterQueries] = useState<
    Partial<IAllTransactionsFields> | Partial<IEquiringFields>
  >({});

  const { t } = useTranslation();

  const params = useParams();
  useEffect(() => {
    setFilterQueries(initialP2PState);
  }, [params?.type]);
  return (
    <div className={sharedStyles.pageContainer}>
      <TransactionsHead />
      {params.id && <ModalComponent id={params.id} />}
      {params?.disputes ? <DisputesStats /> : <TransactionsStats />}
      <SubHeading>
        {params?.disputes
          ? t("disputesPage.subTitle")
          : t("transactionsPage.subTitle")}
      </SubHeading>
      <div className={styles.filters}>
        <div className={styles.filter}>
          {(!params.type || params.type === "all") && (
            <FilterByAllTransactions
              titlePlaceholder={t("transactionsPage.searchParams.title")}
              onSubmit={setFilterQueries}
            />
          )}
          {params.type === "PaymentTransaction::P2P" && (
            <FilterByP2PTransactions
              titlePlaceholder={t("transactionsPage.searchParams.title")}
              onSubmit={setFilterQueries}
            />
          )}
          {params.type === "PaymentTransaction::C2C" && (
            <FilterByAcquiringTransactions
              titlePlaceholder={t("transactionsPage.searchParams.title")}
              onSubmit={setFilterQueries}
            />
          )}
        </div>
        <div className={styles.filter}>
          <FilterByStatus
            setStateQueryParameters={setStateQueryParameters}
            titlePlaceholder={t("transactionsPage.filterByStatus.title")}
          />
        </div>
        <div className={styles.filterSearch}>
          <FilterSearch
            onChange={(data) => {
              data.type === "id" && setId(data.value);
              data.type === "external_id" && setExternalId(data.value);
            }}
          />
        </div>
      </div>
      <TransactionsList
        disputes={params?.disputes ? +params?.disputes : undefined}
        type={params.type}
        stateQueryParameters={stateQueryParameters}
        id={id}
        externalId={externalId}
        filterQueries={filterQueries}
      />
    </div>
  );
}
