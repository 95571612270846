import React from "react";

import styles from "./styles.module.scss";

import { Link } from "react-router-dom";
import Card, { ICard } from "../Card/Card";

interface ICardWithNavigation extends ICard {
  link: string;
}

export default function CardWithNavigation(props: ICardWithNavigation) {
  return (
    <Link to={props.link} className={styles.wrapper}>
      <Card {...props} />
    </Link>
  );
}
