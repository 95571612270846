import React from "react";

import styles from "./styles.module.scss";

import { ReactComponent as CheckIcon } from "@assets/icons/check.svg";
import classNames from "classnames";

interface IItem {
  state: string;
  value: string;
  selected: boolean;
}

interface ICheckBox {
  items: IItem[];
  setItems: React.Dispatch<
    React.SetStateAction<
      {
        state: string;
        value: string;
        selected: boolean;
      }[]
    >
  >;
}

export default function CheckBox(props: ICheckBox) {
  const { items, setItems } = props;

  function itemClick(index: number) {
    let selectedItem: IItem = items[index];
    let newItems = items.map((curItem, curIndex) => {
      if (index !== curIndex) {
        return curItem;
      }
      return { ...selectedItem, selected: !selectedItem.selected };
    });
    setItems(newItems);
  }

  return (
    <ul>
      {items.map((item, index) => (
        <li
          className={classNames(styles.item, {
            [styles.active]: item.selected,
          })}
          key={`${item.value} ${item.selected ? "+" : "-"}`}
          onClick={() => itemClick(index)}
        >
          <span
            className={classNames(styles.box, {
              [styles.active]: item.selected,
            })}
          >
            {item.selected && <CheckIcon className={styles.icon} />}
          </span>
          {item.value}
        </li>
      ))}
    </ul>
  );
}
