import React from "react";

import styles from "./styles.module.scss";
import sharedStyles from "@/utils/styles/sharedStyles.module.scss";

import { Doughnut } from "react-chartjs-2";
import { Chart, ArcElement } from "chart.js";
import { currencyColors } from "@/utils/constants/currencyColors";
import { generateRandomColor } from "@/utils/helpers/generateRandomColor";
import Text from "@/components/typography/Text/Text";
import classNames from "classnames";
import { numberWithSpaces } from "@/utils/helpers/numberWithSpaces";
Chart.register(ArcElement);

const currencyData = [
  { currency: "RUB", amountUSDT: 30, amount: 2000, sign: "₽" },
  { currency: "KZT", amountUSDT: 30, amount: 10000, sign: "₸" },
  { currency: "USDT", amountUSDT: 10, amount: 10, sign: "$" },
  { currency: "USD", amountUSDT: 30, amount: 30, sign: "$" },
  { currency: "UAH", amountUSDT: 0, amount: 0, sign: "₴" },
  { currency: "EUR", amountUSDT: 0, amount: 0, sign: "€" },
  { currency: "TRL", amountUSDT: 0, amount: 0, sign: "₺" },
];

export default function DoughnutChart() {
  return (
    <div className={styles.container}>
      <div style={{ width: "100px", height: "100px" }}>
        <Doughnut
          options={{}}
          data={{
            datasets: [
              {
                data: currencyData.map((data) => data.amountUSDT),
                backgroundColor: currencyData.map((data) => {
                  return (
                    currencyColors[data.currency as "RUB"] ||
                    generateRandomColor()
                  );
                }),
                borderWidth: 0,
                cutout: "70%",
              },
            ] as any,
          }}
        />
      </div>
      <ul className={styles.list}>
        {currencyData
          .filter((data) => data.amount > 0)
          .map((data) => (
            <li key={data.currency} className={styles.item}>
              <span
                style={{
                  backgroundColor:
                    currencyColors[data.currency as "RUB"] ||
                    generateRandomColor(),
                }}
                className={styles.dot}
              />
              <span className={styles.currency}>{data.currency}</span>
              <Text type="accent">
                {data.sign} {numberWithSpaces(data.amount)}
              </Text>
            </li>
          ))}
      </ul>
      <ul className={styles.list}>
        {currencyData
          .filter((data) => data.amount === 0)
          .map((data) => (
            <li key={data.currency} className={styles.item}>
              <span className={classNames(styles.dot, styles.muted)} />
              <span className={classNames(styles.currency, styles.muted)}>
                {data.currency}
              </span>
              <Text type="muted">
                {data.sign} {data.amount}{" "}
              </Text>
            </li>
          ))}
      </ul>
    </div>
  );
}
