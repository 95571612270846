import React from "react";

import { Route, Routes } from "react-router-dom";
import BaseLayout from "../../layout/BaseLayout/BaseLayout";
import Auth from "@/pages/Auth/Auth";
import NotFound from "@/pages/NotFound/NotFound";
import Main from "@/pages/Main/Main";
import Transactions from "@/pages/Transactions/Transactions";
import Balances from "@/pages/Balances/Balances";
import MassPayouts from "@/pages/MassPayouts/MassPayouts";
import ModalPayout from "../../modals/ModalPayout/ModalPayout";

export default function BaseRouter() {
  return (
    <Routes>
      <Route path="/auth" element={<Auth />} />
      <Route path="/" element={<BaseLayout />}>
        <Route index path="/" element={<Transactions />} />
        <Route path="/transaction/:id" element={<Transactions />} />
        <Route path="transactions/" element={<Transactions />}>
          <Route path="transaction/:id" />
          <Route path=":type/">
            <Route path="transaction/:id" />
            <Route path="dispute/:disputes">
              <Route path="transaction/:id" />
            </Route>
          </Route>
        </Route>
        <Route path="balances" element={<Balances />} />
        <Route path="mass-payouts" element={<MassPayouts />}>
          <Route path="create" element={<ModalPayout />} />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
}
