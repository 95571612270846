import React from "react";

import { StyleType } from "@/utils/types/shared";
import { ITransactionResponse } from "@/store/transactions/transactions.types";
import ModalDispute from "../ModalDispute/ModalDispute";
import ModalIntroSwitcher from "../../ModalBase/ModalIntroSwitcher/ModalIntroSwitcher";
import ModalCards from "../ModalCards/ModalCards";
import ModalButtonClose from "../ModalButtonClose/ModalButtonClose";

interface IModalBody {
  type: StyleType;
  createDispute: boolean;
  setCreateDispute: React.Dispatch<React.SetStateAction<boolean>>;
  data: ITransactionResponse | undefined;
  setIsDisputeCreated: React.Dispatch<React.SetStateAction<boolean>>;
  isDisputeCreated: boolean;
}

export default function ModalBody(props: IModalBody) {
  const {
    type,
    createDispute,
    data,
    setCreateDispute,
    setIsDisputeCreated,
    isDisputeCreated,
  } = props;

  if (createDispute && data?.data)
    return (
      <>
        <ModalDispute
          setCreateDispute={setCreateDispute}
          transactionData={data?.data}
          setIsDisputeCreated={setIsDisputeCreated}
        />
        {isDisputeCreated && <ModalButtonClose />}
      </>
    );
  return (
    <>
      {data?.data && (
        <ModalIntroSwitcher
          transactionData={data.data}
          type={type}
          setCreateDispute={setCreateDispute}
        />
      )}

      <ModalCards
        created_at={data?.data.created_at}
        disputes={data?.data.disputes}
        payer_card_bank_issuer={data?.data?.payer_card_bank_issuer}
        payer_card_brand={data?.data?.payer_card_brand}
        payer_card_number={data?.data?.payer_card_number}
        material={data?.data?.material}
      />

      <ModalButtonClose />
    </>
  );
}
