import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import BaseRouter from "./components/shared/Router/BaseRouter";
import { getAccessToken } from "./services/cookies/cookies";

function App() {
  const navigate = useNavigate();
  useEffect(() => {
    const token = getAccessToken();
    if (!token) {
      navigate("/auth");
    }
  }, []);

  return (
    <>
      <BaseRouter />
    </>
  );
}

export default App;
