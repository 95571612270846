import React from "react";

import sharedStyles from "@utils/styles/sharedStyles.module.scss";
import styles from "./styles.module.scss";

import classNames from "classnames";
import Avatar from "@/components/shared/Avatar/Avatar";
import CalculatedValue from "@/components/shared/CalculatedValue/CalculatedValue";
import { StyleType } from "@/utils/types/shared";
// import moment from "moment";
// import "moment/locale/ru";
import { Link, useLocation } from "react-router-dom";
import TransactionChip from "../TransactionChip/TransactionChip";
import { parseDate } from "@/utils/helpers/parseDate";

interface ITransactionValue {
  value: string;
  valueInUSDT: string;
}

interface ICommissionsValue extends ITransactionValue {
  percentage: string;
}

interface ITransactionData {
  id: string;
  status: string;
  date: string;
  bankLabel: string;
  card: string;
  chipText: string;
  commission: ICommissionsValue;
  transaction: ITransactionValue;
  disputes: any[];
}
interface ITransactionItem {
  avatar: React.ReactElement;
  avatarBadge?: React.ReactElement | null | string;
  type?: StyleType;
  bankLogo?: React.ReactElement;
  data: ITransactionData;
}

export default function TransactionItem(props: ITransactionItem) {
  const { avatar, type = "primary", data, bankLogo, avatarBadge } = props;
  const location = useLocation();

  return (
    <li>
      <Link
        to={`${location.pathname != "/" ? location.pathname : ""}/transaction/${
          data.id
        }`}
        className={classNames(sharedStyles.cardContainer, styles.container)}
      >
        <div className={styles.status}>
          <Avatar mode="square" type={type} badge={avatarBadge} size="md">
            {avatar}
          </Avatar>
          <div className={styles.statusContent}>
            <p className={styles.statusText}>
              <span>{data.status}</span>
              {data.disputes.length === 2 && (
                <span className={styles.textCount}> x2</span>
              )}
            </p>
            <p className={styles.date}>
              {/* {moment(data.date).locale("ru").calendar()} */}
              {parseDate(data.date)}
            </p>
          </div>
        </div>
        <div className={styles.number}>{data.card}</div>
        <div className={styles.bank}>
          <span className={styles.logo}>{bankLogo}</span>
          <span className={styles.label}>{data.bankLabel}</span>
        </div>
        <div className={styles.commission}>
          <div>
            <CalculatedValue
              mode="money"
              size="sm"
              value={data.commission.value}
              moneyPercentage={data.commission.percentage}
              moneyTransparent={true}
            />
          </div>
          <p className={styles.moneyInUSDT}>{data.commission.valueInUSDT}</p>
        </div>
        <div className={styles.transaction}>
          <CalculatedValue
            mode="money"
            type={
              type === "danger" || type === "successLight" ? type : undefined
            }
            size="sm"
            value={data.transaction.value}
          />
          <p className={styles.moneyInUSDT}>{data.transaction.valueInUSDT}</p>
        </div>
        <div className={styles.chip}>
          <TransactionChip type={type}>{data.chipText}</TransactionChip>
        </div>
      </Link>
    </li>
  );
}
