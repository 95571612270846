import classNames from "classnames";
import React from "react";

import styles from "./styles.module.scss";

export default function ControlBox({
  children,
  type,
  title,
}: {
  children: React.ReactNode;
  type?: "error" | "empty";
  title?: string;
}) {
  return (
    <>
      {title && (
        <h5
          className={classNames(styles.title, {
            [styles.error]: type === "error",
          })}
        >
          {title}
        </h5>
      )}
      <div
        className={classNames(styles.box, {
          [styles.muted]: type === "empty",
          [styles.error]: type === "error",
        })}
      >
        {children}
      </div>
    </>
  );
}
