import React, { useState } from "react";

import styles from "./styles.module.scss";

import { ReactComponent as ShareIcon } from "@assets/icons/share.svg";
import useCopyToClipboard from "@/utils/hooks/useCopyToClipboard";
import classNames from "classnames";

export default function ModalShare() {
  const [isCopied, setIsCopied] = useState(false);

  const [, copy] = useCopyToClipboard();

  async function copyToClipboard() {
    const isSuccess = await copy(window.location.href);
    isSuccess === true && setIsCopied(true);
  }
  return (
    <button
      onClick={copyToClipboard}
      className={classNames(styles.share, { [styles.active]: isCopied })}
    >
      <ShareIcon />
      Поделиться
    </button>
  );
}
