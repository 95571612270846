import React from "react";

import styles from "../styles.module.scss";

import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export default function ModalCardLoader() {
  return (
    <div className={styles.container}>
      <Skeleton className={styles.title} height={14} width="40%" />
      <Skeleton className={styles.content} height={18} width="70%" />
    </div>
  );
}
