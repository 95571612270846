import React from "react";

import styles from "./styles.module.scss";
import sharedStyles from "@utils/styles/sharedStyles.module.scss";

import { ReactComponent as CancelIcon } from "@assets/icons/cancel.svg";
import { StyleType } from "@/utils/types/shared";
import classNames from "classnames";
import Text from "@/components/typography/Text/Text";
import Avatar from "@/components/shared/Avatar/Avatar";
// import moment from "moment";
// import "moment/locale/ru";
interface IModalHead {
  type: StyleType;
  closeModal(): void;
  closeIcon?: React.ReactElement;
  avatarIcon: React.ReactElement;
  avatarBadgeContent?: React.ReactElement | string | null;
  text?: string;
}

export default function ModalHead(props: IModalHead) {
  const { type, closeModal, closeIcon, avatarIcon, avatarBadgeContent, text } =
    props;

  return (
    <div className={styles.headerWrap}>
      <div
        className={classNames(styles.header, {
          [sharedStyles.loading]: type === "loading",
          [sharedStyles.primary]: type === "primary",
          [sharedStyles.accent]: type === "accent",
          [sharedStyles.success]: type === "success",
          [sharedStyles.danger]: type === "danger",
          [sharedStyles.warning]: type === "warning",
          [sharedStyles.successLight]: type === "successLight",
        })}
      >
        <div className={styles.empty} />

        <div className={styles.date}>
          {text && <Text type={type}>{text}</Text>}
        </div>

        <button onClick={closeModal}>
          <Avatar size="sm" type={type} mode="bgDark">
            {closeIcon || <CancelIcon />}
          </Avatar>
        </button>
        {/* )} */}
      </div>
      <div className={styles.avatar}>
        <Avatar
          size="lg"
          mode="squareOutline"
          badge={avatarBadgeContent}
          type={type}
        >
          {avatarIcon}
        </Avatar>
      </div>
    </div>
  );
}
