import React from "react";

import styles from "../TransactionsList/styles.module.scss";

import TransactionItem from "../TransactionItem/TransactionItem";
import { ReactComponent as CheckIcon } from "@assets/icons/check.svg";
import { ReactComponent as CancelIcon } from "@assets/icons/cancel.svg";
import { ReactComponent as CardIcon } from "@assets/icons/card.svg";
import { ReactComponent as ClockIcon } from "@assets/icons/clock-sm.svg";
import { ReactComponent as SwapIcon } from "@assets/icons/swap-small.svg";
import { ReactComponent as TincoffLogo } from "@assets/icons/tinkoff.svg";
import { ReactComponent as SberLogo } from "@assets/icons/sber.svg";
import { ReactComponent as OtkritieLogo } from "@assets/icons/otkritie.svg";
import { ReactComponent as QiwiLogo } from "@assets/icons/qiwi.svg";
import { ReactComponent as AlphaLogo } from "@assets/icons/alpha.svg";
import { ReactComponent as VtbLogo } from "@assets/icons/vtb.svg";
import { StyleType } from "@/utils/types/shared";
import { ITransactionListItem } from "@/store/transactions/transactions.types";
import AvatarIconSwitcher from "../AvatarIconSwitcher/AvatarIconSwitcher";
import { useTranslation } from "react-i18next";
import Loader from "@/components/shared/Loader/Loader";
import { transactionStatusStyle } from "@/utils/constants/transactionStatusType";
import useAvatarBadgeSwitcher from "@/utils/hooks/useAvatarBadgeSwither";
import {
  getChipText,
  getTransactionTitle,
  getTransactionType,
} from "@/utils/helpers/transactions";

interface ITransactionsListMapper {
  data?: ITransactionListItem[];
  error: any;
  isLoading?: boolean;
}

const sign = "₽";

export default function TransactionsListMapper(props: ITransactionsListMapper) {
  const { data, error, isLoading } = props;

  const { t } = useTranslation();

  const { avatarBadgeSwitcher } = useAvatarBadgeSwitcher();

  if (error) {
    return <div className={styles.message}>{error?.data?.status}</div>;
  }
  return (
    <>
      <ul className={styles.list}>
        {data?.map((item) => (
          <TransactionItem
            key={item.id}
            avatar={
              <AvatarIconSwitcher
                short_type={item.short_type}
                state={item.state}
                disputes={item.disputes}
              />
            }
            avatarBadge={avatarBadgeSwitcher({
              short_type: item.short_type,
              state: item.state,
              disputes: item.disputes,
            })}
            type={getTransactionType(item)}
            bankLogo={
              item?.payer_card_bank_logo ? (
                <img
                  src={item?.payer_card_bank_logo}
                  className={styles.bankLogo}
                  alt="bank_logo"
                />
              ) : (
                <></>
              )
            }
            data={{
              transaction: {
                value: `${sign} ${item.amount}`,
                valueInUSDT: `${item.amount_usdt} USDT`,
              },
              commission: {
                percentage: `${item.comission_percent}%`,
                value: `${sign} ${item.comission}`,
                valueInUSDT: `${item.comission_usdt} USDT`,
              },
              status: getTransactionTitle(item),
              date: item.updated_at,
              bankLabel: item.payer_card_bank_issuer ?? "",
              card: item.payer_card_number ?? "",
              chipText: getChipText(item),
              id: item.id as string,
              disputes: item.disputes,
            }}
          />
        ))}
      </ul>
      {isLoading && (
        <div className={styles.loader}>
          <Loader />
        </div>
      )}
    </>
  );
  // return (
  //   <ul className={styles.list}>
  //     <TransactionItem
  //       avatar={<CheckIcon />}
  //       type="successLight"
  //       bankLogo={<SberLogo />}
  //       data={{
  //         transaction: {
  //           value: `${sign} 12 000,83`,
  //           valueInUSDT: "214,33 USDT",
  //         },
  //         commission: {
  //           percentage: "10%",
  //           value: `${sign} 135,83 `,
  //           valueInUSDT: "2,10 USDT",
  //         },
  //         status: "Транзакция выполнена",
  //         date: "2022-12-14T17:00:00.000+03:00",
  //         bankLabel: "Сбербанк",
  //         card: "553616******7722",
  //         chipText: "Выполнена",
  //       }}
  //     />
  //     <TransactionItem
  //       avatar={<>lim</>}
  //       type="primary"
  //       bankLogo={<SberLogo />}
  //       data={{
  //         transaction: {
  //           value: `${sign} 12 000,83`,
  //           valueInUSDT: "214,33 USDT",
  //         },
  //         commission: {
  //           percentage: "10%",
  //           value: `${sign} 135,83 `,
  //           valueInUSDT: "2,10 USDT",
  //         },
  //         status: "Лимит по карте",
  //         date: "2022-12-14T17:00:00.000+03:00",
  //         bankLabel: "Сбербанк",
  //         card: "553616******7722",
  //         chipText: "Лимит",
  //       }}
  //     />
  //     <TransactionItem
  //       avatar={<CancelIcon />}
  //       type="danger"
  //       bankLogo={<OtkritieLogo />}
  //       data={{
  //         transaction: {
  //           value: `${sign} 12 000,83`,
  //           valueInUSDT: "214,33 USDT",
  //         },
  //         commission: {
  //           percentage: "10%",
  //           value: `${sign} 135,83 `,
  //           valueInUSDT: "2,10 USDT",
  //         },
  //         status: "Транзакция отклонена",
  //         date: "2022-12-14T17:00:00.000+03:00",
  //         bankLabel: "Открытие",
  //         card: "553616******7722",
  //         chipText: "Отклонена",
  //       }}
  //     />
  //     <TransactionItem
  //       avatar={<CardIcon />}
  //       type="accent"
  //       bankLogo={<QiwiLogo />}
  //       data={{
  //         transaction: {
  //           value: `${sign} 12 000,83`,
  //           valueInUSDT: "214,33 USDT",
  //         },
  //         commission: {
  //           percentage: "10%",
  //           value: `${sign} 135,83 `,
  //           valueInUSDT: "2,10 USDT",
  //         },
  //         status: "Введена карта",
  //         date: "2022-12-14T17:00:00.000+03:00",
  //         bankLabel: "Qiwi",
  //         card: "553616******7722",
  //         chipText: "Введена карта",
  //       }}
  //     />
  //     <TransactionItem
  //       avatar={<>3Ds</>}
  //       type="success"
  //       bankLogo={<VtbLogo />}
  //       data={{
  //         transaction: {
  //           value: `${sign} 12 000,83`,
  //           valueInUSDT: "214,33 USDT",
  //         },
  //         commission: {
  //           percentage: "10%",
  //           value: `${sign} 135,83 `,
  //           valueInUSDT: "2,10 USDT",
  //         },
  //         status: "На странице 3DS",
  //         date: "2022-12-14T17:00:00.000+03:00",
  //         bankLabel: "ВТБ",
  //         card: "553616******7722",
  //         chipText: "3DS Secure",
  //       }}
  //     />
  //     <TransactionItem
  //       avatar={<ClockIcon />}
  //       type="warning"
  //       bankLogo={<AlphaLogo />}
  //       data={{
  //         transaction: {
  //           value: `${sign} 163,83`,
  //           valueInUSDT: "2,33 USDT",
  //         },
  //         commission: {
  //           percentage: "0%",
  //           value: `${sign} 0`,
  //           valueInUSDT: "Без комиссии",
  //         },
  //         status: "Транзакция в обработке",
  //         date: "2022-12-14T17:00:00.000+03:00",
  //         bankLabel: "Альфа Банк",
  //         card: "553616******7722",
  //         chipText: "Обработка",
  //       }}
  //     />
  //     <TransactionItem
  //       avatar={<SwapIcon />}
  //       type="primary"
  //       bankLogo={<TincoffLogo />}
  //       data={{
  //         transaction: {
  //           value: `${sign} 12 000,83`,
  //           valueInUSDT: "214,33 USDT",
  //         },
  //         commission: {
  //           percentage: "10%",
  //           value: `${sign} 135,83 `,
  //           valueInUSDT: "2,10 USDT",
  //         },
  //         status: "Создание платежа",
  //         date: "2022-12-14T17:00:00.000+03:00",
  //         bankLabel: "Тинькофф",
  //         card: "553616******7722",
  //         chipText: "Создание",
  //       }}
  //     />
  //     <TransactionItem
  //       avatar={<SwapIcon />}
  //       type="warning"
  //       bankLogo={<TincoffLogo />}
  //       data={{
  //         transaction: {
  //           value: `${sign} 12 000,83`,
  //           valueInUSDT: "214,33 USDT",
  //         },
  //         commission: {
  //           percentage: "10%",
  //           value: `${sign} 135,83 `,
  //           valueInUSDT: "2,10 USDT",
  //         },
  //         status: "Ожидание оплаты",
  //         date: "2022-12-14T17:00:00.000+03:00",
  //         bankLabel: "Сбербанк",
  //         card: "553616******7722",
  //         chipText: "Ожидание",
  //       }}
  //     />
  //     <TransactionItem
  //       avatar={<SwapIcon />}
  //       type="successLight"
  //       bankLogo={<TincoffLogo />}
  //       data={{
  //         transaction: {
  //           value: `${sign} 12 000,83`,
  //           valueInUSDT: "214,33 USDT",
  //         },
  //         commission: {
  //           percentage: "10%",
  //           value: `${sign} 135,83 `,
  //           valueInUSDT: "2,10 USDT",
  //         },
  //         status: "Платеж зачислен",
  //         date: "2022-12-14T17:00:00.000+03:00",
  //         bankLabel: "Открытие",
  //         card: "553616******7722",
  //         chipText: "Зачислен",
  //       }}
  //     />
  //     <TransactionItem
  //       avatar={<SwapIcon />}
  //       type="accent"
  //       bankLogo={<TincoffLogo />}
  //       data={{
  //         transaction: {
  //           value: `${sign} 12 000,83`,
  //           valueInUSDT: "214,33 USDT",
  //         },
  //         commission: {
  //           percentage: "10%",
  //           value: `${sign} 135,83 `,
  //           valueInUSDT: "2,10 USDT",
  //         },
  //         status: "Платеж оплачен",
  //         date: "2022-12-14T17:00:00.000+03:00",
  //         bankLabel: "Qiwi",
  //         card: "553616******7722",
  //         chipText: "Оплачен",
  //       }}
  //     />
  //   </ul>
  // );
}
