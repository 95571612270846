import baseQueryWithToken from "../base-query";
import { createApi } from "@reduxjs/toolkit/query/react";

export interface Data {
  username: string;
  email: string;
  merchant_id: number;
  roles: string[];
}

export interface Meta {}

export interface IUserInfoResponse {
  data: Data;
  meta: Meta;
  errors: any[];
  message?: any;
  status: string;
}

export const userApi = createApi({
  reducerPath: "userApi",
  baseQuery: baseQueryWithToken,
  endpoints: (builder) => ({
    getUserInfo: builder.query<IUserInfoResponse, void>({
      query: () => `/api/v1/user/me`,
    }),
  }),
});

export const { useGetUserInfoQuery } = userApi;
