import React from "react";

import styles from "./styles.module.scss";

export default function ModalTitle({
  children,
  titleRight,
}: {
  children: React.ReactNode;
  titleRight?: string;
}) {
  return (
    <h5 className={styles.title}>
      {children}
      {titleRight && <span className={styles.count}>{titleRight}</span>}
    </h5>
  );
}
