import React, { useState, useMemo } from "react";

import styles from "./styles.module.scss";

import FilterBase from "../FilterBase/FilterBase";
import InputWithLabel from "@/components/forms/InputWithLabel/InputWithLabel";
import Input from "@/components/forms/Input/Input";
import DatePickerComponent from "@/components/forms/DatePicker/DatePicker";
import getTitle from "@/utils/helpers/filterTitle";
import DropDownWithLabel from "@/components/controls/DropDownWithLabel/DropDownWithLabel";
import { useTranslation } from "react-i18next";
import {
  IAllTransactionsFields,
  ITransactionsFilter,
} from "@/utils/types/filterTransactions";

export const initialAllState = {
  amountGt: "",
  amountLt: "",
  currency: "",
  dateGt: "",
  dateLt: "",
};

export default function FilterByAllTransactions(props: ITransactionsFilter) {
  const { titlePlaceholder, onSubmit } = props;

  const [state, setState] = useState<IAllTransactionsFields>(initialAllState);
  const { t } = useTranslation();

  function onFieldChange(value: string | Date, fieldKey: string) {
    setState((currentState) => ({ ...currentState, [fieldKey]: value }));
  }

  function filterSubmit() {
    onSubmit(state);
  }

  function clearState() {
    setState(initialAllState);
    onSubmit(initialAllState);
  }

  const title = useMemo(() => getTitle(state), [state]);

  return (
    <FilterBase
      titlePlaceholder={titlePlaceholder}
      title={title}
      onSubmit={filterSubmit}
      onCancel={clearState}
    >
      <div className={styles.container}>
        <div className={styles.filterContainer}>
          <InputWithLabel
            label={t("transactionsPage.searchParams.sum.from.title")}
            size="sm"
            mode="light"
            inputAttributes={{
              type: "number",
              name: "amountGt",
              value: state.amountGt,
              onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                onFieldChange(e.target.value, "amountGt"),
              placeholder: t(
                "transactionsPage.searchParams.sum.from.placeHolder"
              ).toString(),
            }}
            rightElement={<span>₽</span>}
          />
          <Input
            size="sm"
            mode="light"
            inputAttributes={{
              type: "number",
              name: "amountLt",
              value: state.amountLt,
              onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                onFieldChange(e.target.value, "amountLt"),
              placeholder: t(
                "transactionsPage.searchParams.sum.to.placeHolder"
              ).toString(),
            }}
            rightElement={<span>₽</span>}
          />
          <DropDownWithLabel
            items={["Рубль(₽)", "Тенге(T)"]}
            mode="outline"
            label={t("transactionsPage.searchParams.sum.currency.title")}
          />
        </div>
        <div className={styles.dates}>
          <div>
            <DatePickerComponent
              label={
                t("transactionsPage.searchParams.date.from.title") as string
              }
              onChange={(date: Date | string) => onFieldChange(date, "dateGt")}
              placeholder={
                t(
                  "transactionsPage.searchParams.sum.from.placeHolder"
                ) as string
              }
              selected={state.dateGt}
            />
          </div>
          <div>
            <DatePickerComponent
              placeholder={
                t("transactionsPage.searchParams.sum.to.placeHolder") as string
              }
              onChange={(date: Date | string) => onFieldChange(date, "dateLt")}
              selected={state.dateLt}
            />
          </div>
        </div>
      </div>
    </FilterBase>
  );
}
