import Chip from "@/components/shared/Chip/Chip";
import { IStyledComponent } from "@/utils/types/shared";
import React from "react";

import styles from "./styles.module.scss";

export default function TransactionChip(props: IStyledComponent) {
  return (
    <span className={styles.wrap}>
      <Chip {...props} />
    </span>
  );
}
