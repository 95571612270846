import React from "react";

import styles from "../styles.module.scss";

import { ReactComponent as Chevron } from "@assets/icons/chevron-down.svg";
import Skeleton from "react-loading-skeleton";

export default function HeaderDdLoader() {
  return (
    <div className={styles.userContainer}>
      <div className={styles.user}>
        <Skeleton width={40} height={40} circle />
        <div className={styles.info}>
          <Skeleton width={95} height={16} className={styles.title} />
          <Skeleton width={95} height={12} className={styles.subTitle} />
        </div>
      </div>
      <Chevron className={styles.chevron} />
    </div>
  );
}
