import React from "react";

import { IStyledComponent } from "@/utils/types/shared";
import classNames from "classnames";

import styles from "./styles.module.scss";
import sharedStyles from "@utils/styles/sharedStyles.module.scss";
import { Link } from "react-router-dom";
import { ReactComponent as LinkIcon } from "@assets/icons/link.svg";

interface IChip extends IStyledComponent {
  link?: string;
  mode?: "currency" | "state";
}

export default function Chip(props: IChip) {
  const { children, type, link, mode } = props;

  function ChipBase() {
    return (
      <span
        className={classNames(styles.container, {
          [sharedStyles.primary]: type === "primary",
          [sharedStyles.accent]: type === "accent",
          [sharedStyles.success]: type === "success",
          [sharedStyles.danger]: type === "danger",
          [sharedStyles.warning]: type === "warning",
          [sharedStyles.successLight]: type === "successLight",

          [styles.currency]: mode === "currency",

          [styles.state]: mode === "state",
        })}
      >
        {children}
      </span>
    );
  }

  if (link) {
    return (
      <Link to={link} className={styles.linkWrapper}>
        <LinkIcon className={styles.linkIcon} /> {ChipBase()}
      </Link>
    );
  }
  return ChipBase();
}
