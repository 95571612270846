import React from "react";

import styles from "./styles.module.scss";

import { ReactComponent as SwitchIcon } from "@assets/icons/switch-horizontal.svg";
import { ReactComponent as CalendarIcon } from "@assets/icons/calendar.svg";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Heading from "@/components/typography/Heading/Heading";
import SubHeading from "@/components/typography/SubHeading/SubHeading";
import Popover from "@/components/shared/Popover/Popover";
import classNames from "classnames";
import DropDown from "@/components/controls/DropDown/DropDown";

export default function TransactionsHead() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();

  const transactionTypes = [
    { label: t("transactionsPage.typeSelect.all"), value: "" },
    {
      label: t("transactionsPage.typeSelect.p2p"),
      value: "PaymentTransaction::P2P",
    },
    {
      label: t("transactionsPage.typeSelect.c2c"),
      value: "PaymentTransaction::C2C",
    },
  ];

  function onDdChange(label: string) {
    const item = transactionTypes.find((type) => type.label === label);
    if (item) {
      navigate(
        "/transactions/" +
          (item.value === "" ? "all" : item.value) +
          (params.disputes ? `/dispute/${params.disputes}` : "")
      );
    }
  }

  const selected = transactionTypes.find((t) => t.value === params.type);

  return (
    <>
      <div className={styles.header}>
        {
          <Heading>
            {params.disputes
              ? t("disputesPage.title")
              : t("transactionsPage.title")}
          </Heading>
        }
      </div>
      <div className={styles.controlsTitle}>
        <SubHeading
          rightElement={
            <Popover mode={"inline"}>
              {t("modals.transaction.hints.check")}
            </Popover>
          }
        >
          Параметры отображения статистики
        </SubHeading>
      </div>
      <div className={styles.controls}>
        <div className={classNames(styles.type, styles.control)}>
          <SwitchIcon className={styles.controlIcon} />
          <div className={styles.typeText}>
            {t("transactionsPage.typeSelect.title")}
          </div>
          <div className={styles.controlDd}>
            <DropDown
              mode="heightFull"
              items={transactionTypes.map((type) => type.label)}
              onChange={onDdChange}
              selected={selected?.label}
            />
          </div>
        </div>
        <div className={classNames(styles.statsDd, styles.control)}>
          <CalendarIcon className={styles.controlIcon} />
          <div className={styles.typeText}>
            {t("transactionsPage.statsSelect.title")}
          </div>
          <div className={styles.controlDd}>
            <DropDown
              items={["За сегодня", "Неделя", "Месяц", "Все время"]}
              mode="heightFull"
            />
          </div>
        </div>
      </div>
    </>
  );
}
