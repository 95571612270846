import React, { useState } from "react";

import styles from "../Section/styles.module.scss";

import classNames from "classnames";
import { IRoute, ISubRoute } from "../Section/Section";
import { ReactComponent as Chevron } from "@assets/icons/chevron-down.svg";
import { NavLink, useParams } from "react-router-dom";

interface ISectionDdItem extends IRoute {
  route: ISubRoute[];
}

export default function SectionDdItem(props: ISectionDdItem) {
  const { route, name, icon } = props;

  const { type } = useParams();
  const [isActive, setIsActive] = useState(false);

  function toggleActive() {
    setIsActive((isActive) => !isActive);
  }

  return (
    <div className={styles.ddWrapper}>
      <button className={classNames(styles.link)} onClick={toggleActive}>
        <div className={styles.icon}>{icon}</div>
        <span className={styles.ddLabel}>
          <span>{name}</span>
          <Chevron
            className={classNames(styles.ddArrow, {
              [styles.active]: isActive,
            })}
          />
        </span>
      </button>
      <ul
        className={classNames(styles.dropDown, {
          [styles.visible]: isActive,
        })}
      >
        {route?.map(({ route, name }, index) => (
          <li key={index} className={styles.ddItemWrap}>
            <NavLink
              to={route}
              className={({ isActive }) =>
                classNames(styles.ddItem, {
                  [styles.active]: isActive && route.includes(type || "/all"),
                })
              }
            >
              {name}
            </NavLink>
          </li>
        ))}
      </ul>
    </div>
  );
}
