import React, { useRef, useState, useEffect } from "react";

import styles from "./styles.module.scss";

import classNames from "classnames";
import { ReactComponent as Chevron } from "@assets/icons/chevron-down.svg";
import useOnClickOutside from "@/utils/hooks/useClickOutside";

export interface IDropDown {
  mode?: "outline" | "accentOutline" | "search" | "heightFull" | "left";
  items: string[];
  placeholder?: string;
  onChange?: (value: string, index?: number) => void;
  onActiveChange?: (value: boolean) => void;
  selected?: string;
}

export default function DropDown(props: IDropDown) {
  const { mode, items, placeholder, onChange, selected, onActiveChange } =
    props;

  const [isDdVisible, setIsDdVisible] = useState(false);
  const [selectedItem, setSelectedItem] = useState("");

  const ddRef = useRef<HTMLUListElement>(null);
  const togglerRef = useRef<HTMLButtonElement>(null);

  function hideDd() {
    setIsDdVisible(false);
  }

  useOnClickOutside(ddRef, hideDd, togglerRef);

  function toggleVisible() {
    setIsDdVisible((curVal) => !curVal);
  }
  function selectItem(item: string, index?: number) {
    if (item == selectedItem) {
      return;
    }
    setSelectedItem(item);
    onChange && onChange(item, index);
    hideDd();
  }

  useEffect(() => {
    if (selected) {
      setSelectedItem(selected);
    } else {
      setSelectedItem(placeholder ? placeholder : items[0]);
    }
  }, [selected]);

  useEffect(() => {
    onActiveChange && onActiveChange(isDdVisible);
  }, [isDdVisible]);
  return (
    <div
      className={classNames(styles.container, {
        [styles.outline]: mode === "outline" || mode === "accentOutline",
        [styles.accentOutline]: mode === "accentOutline",
        [styles.search]: mode === "search",
        [styles.heightFull]: mode === "heightFull",
        [styles.left]: mode === "left",
      })}
    >
      <button
        ref={togglerRef}
        className={styles.button}
        onClick={toggleVisible}
      >
        <span className={styles.selectedItem}>{selected || selectedItem}</span>
        <Chevron
          className={classNames(styles.chevron, {
            [styles.active]: isDdVisible,
          })}
        />
      </button>
      <ul
        ref={ddRef}
        className={classNames(styles.dropDown, {
          [styles.visible]: isDdVisible,
        })}
      >
        {placeholder && (
          <li
            key={placeholder}
            className={classNames(styles.item, {
              [styles.active]: placeholder === selectedItem,
            })}
            onClick={() => selectItem(placeholder)}
          >
            {placeholder}
          </li>
        )}
        {items
          .filter((i) => i !== placeholder)
          .map((item, index) => (
            <li
              key={item}
              className={classNames(styles.item, {
                [styles.active]: item === selectedItem,
              })}
              onClick={() => selectItem(item, index)}
            >
              {item}
            </li>
          ))}
      </ul>
    </div>
  );
}
