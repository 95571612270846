import general from "./general.json";
import navigation from "./navigation.json";
import modals from "./modals.json";
import transactionsPage from "./transactionsPage.json";
import mainPage from "./mainPage.json";
import payoutsPage from "./payoutsPage.json";
import disputesPage from "./disputesPage.json";
import balancesPage from "./balancesPage.json";

const ru = {
  ...navigation,
  ...general,
  ...modals,
  ...transactionsPage,
  ...mainPage,
  ...payoutsPage,
  ...disputesPage,
  ...balancesPage,
};
export default ru;
