import React from "react";

import styles from "./styles.module.scss";
import sharedStyles from "@utils/styles/sharedStyles.module.scss";

import classNames from "classnames";
import CalculatedValue from "../../shared/CalculatedValue/CalculatedValue";

export interface ICard {
  type?: "default" | "danger";
  link?: string;
  title: string | React.ReactElement;
  subTitle?: string;
  body: string;
  bodyLeftSign?: string;
  bodyPercent?: string;
  footer: string | React.ReactElement;
  titleElement?: React.ReactElement;
  footerElement?: React.ReactElement;
}

export default function Card(props: ICard) {
  const {
    type = "default",
    link,
    title,
    body,
    footer,
    titleElement,
    footerElement,
    bodyLeftSign,
    bodyPercent,
    subTitle,
  } = props;
  return (
    <div
      className={classNames(sharedStyles.cardContainer, styles.container, {
        [styles.link]: Boolean(link),
      })}
    >
      <div className={styles.headerContainer}>
        <div className={styles.header}>
          <h4>{title}</h4>
          {titleElement && (
            <div className={classNames(styles.titleElement, {})}>
              {titleElement}
            </div>
          )}
        </div>
        {subTitle && <div className={styles.subTitle}>{subTitle}</div>}
      </div>

      <div>
        <CalculatedValue
          moneyLeft={bodyLeftSign}
          value={body}
          mode="money"
          moneyPercentage={bodyPercent}
        />
        <div className={styles.footer}>
          <span>{footer}</span>
          {footerElement && (
            <div
              className={classNames(styles.footerElement, {
                [styles.danger]: type === "danger",
                [styles.default]: type === "default",
              })}
            >
              {footerElement}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
