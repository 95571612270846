import React from "react";

import ModalCardsLoader from "../ModalCards/ModalCardsLoader/ModalCardsLoader";
import ModalButtonClose from "../ModalButtonClose/ModalButtonClose";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export default function ModalLoader() {
  return (
    <>
      <Skeleton width={200} height={20} style={{ marginBottom: 10 }} />

      <Skeleton width={300} height={18} style={{ marginBottom: 20 }} />

      <Skeleton width={120} height={20} style={{ marginBottom: 10 }} />

      <Skeleton width={180} height={15} style={{ marginBottom: 20 }} />

      <Skeleton width={110} height={18} style={{ marginBottom: 20 }} />

      <ModalCardsLoader />

      <ModalButtonClose />
    </>
  );
}
