import { getAccessToken } from "@/services/cookies/cookies";
import { fetchBaseQuery } from "@reduxjs/toolkit/dist/query";

const baseQueryWithToken = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_BASE_URL,
  prepareHeaders: (headers) => {
    const token = getAccessToken();
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

export default baseQueryWithToken;
