import React, { useState, useRef } from "react";

import styles from "./styles.module.scss";

import classNames from "classnames";

export interface IInput {
  type?: "error" | "empty";
  size?: "sm";
  mode?: "light" | "search";
  leftElement?: React.ReactElement;
  rightElement?: React.ReactElement;
  inputAttributes?: any;
  borderType?: "md";
  // isDate?: boolean;
}

export default function Input(props: IInput) {
  const {
    type,
    leftElement,
    rightElement,
    inputAttributes,
    mode,
    size,
    borderType,
    ...rest
  } = props;
  const [isFocused, setIsFocused] = useState(false);

  const inputRef = useRef<HTMLInputElement | null>(null);

  function focusOnInput() {
    inputRef.current?.focus();
  }

  function onFocus() {
    setIsFocused(true);
  }
  return (
    <div
      onClick={focusOnInput}
      className={classNames(styles.container, {
        [styles.active]: isFocused,
        [styles.error]: type === "error",
        [styles.empty]: type === "empty",

        [styles.light]: mode === "light",
        [styles.search]: mode === "search",
        [styles.xs]: size === "sm",

        [styles.borderMd]: borderType === "md",
      })}
    >
      {leftElement && <div className={styles.leftElement}>{leftElement}</div>}
      <input
        ref={inputRef}
        className={styles.input}
        onFocus={onFocus}
        onBlur={() => setIsFocused(false)}
        {...inputAttributes}
        {...rest}
      />
      {rightElement && (
        <div
          className={classNames(styles.rightElement, {
            [styles.date]: inputRef?.current?.type === "date",
          })}
        >
          {rightElement}
        </div>
      )}
    </div>
  );
}
