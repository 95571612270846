import { IAllTransactionsFields } from "../types/filterTransactions";
import i18n from "i18next";

export default function getTitle(state: IAllTransactionsFields) {
  let title = [];
  type stateKey = keyof typeof state;
  for (let key in state) {
    if (!state[key as stateKey]) {
      continue;
    }
    switch (key) {
      case "amountGt": {
        title.push(
          i18n.t("transactionsPage.searchParams.sum.from.placeHolder") +
            ` 
             ${state[key as stateKey]} ₽ `
        );
        break;
      }

      case "amountLt": {
        title.push(
          `${i18n.t("transactionsPage.searchParams.sum.to.placeHolder")}
             ${state[key as stateKey]} ₽,`
        );
        break;
      }
      case "dateGt": {
        title.push(
          `${i18n.t("transactionsPage.searchParams.date.from.placeHolder")}
             ${state[key as stateKey]
               .toLocaleString()
               .slice(0, -3)
               .replace(",", "")}`
        );
        break;
      }
      case "dateLt": {
        title.push(
          `${i18n.t("transactionsPage.searchParams.date.to.placeHolder")}
             ${state[key as stateKey]
               .toLocaleString()
               .slice(0, -3)
               .replace(",", "")} , `
        );
        break;
      }

      case "sellerCardNumber": {
        title.push(
          `${i18n.t(
            "transactionsPage.searchParams.paymentSenderRecipient.cardNumber.title"
          )} ${state[key as stateKey]}`
        );
        break;
      }
      case "sellerCardBrand": {
        title.push(
          `${i18n.t(
            "transactionsPage.searchParams.paymentSenderRecipient.paySystem.title"
          )} ${state[key as stateKey]}`
        );
        break;
      }
      case "sellerCardBankAlias": {
        title.push(
          `${i18n.t(
            "transactionsPage.searchParams.paymentSenderRecipient.bank.title"
          )} ${state[key as stateKey]}`
        );
        break;
      }

      case "payerCardNumber": {
        title.push(
          `${i18n.t(
            "transactionsPage.searchParams.paymentSenderRecipient.cardNumber.title"
          )} ${state[key as stateKey]}`
        );
        break;
      }
      case "payerCardBrand": {
        title.push(
          `${i18n.t(
            "transactionsPage.searchParams.paymentSenderRecipient.paySystem.title"
          )} ${state[key as stateKey]}`
        );
        break;
      }
      case "payerCardBankAlias": {
        title.push(
          `${i18n.t(
            "transactionsPage.searchParams.paymentSenderRecipient.bank.title"
          )} ${state[key as stateKey]}`
        );
        break;
      }
    }
  }

  if (!title.length) {
    return;
  }
  title[title.length - 1] = title[title.length - 1].replace(",", "");
  return title?.join(" ");
}
