import React from "react";

import styles from "./styles.module.scss";

import sharedStyles from "@/utils/styles/sharedStyles.module.scss";

import BalancesHead from "./BalancesHead/BalancesHead";
import BalancesAll from "./BalancesAll/BalancesAll";
import BalancesAccounts from "./BalancesAccounts/BalancesAccounts";

export default function Balances() {
  return (
    <div className={sharedStyles.pageContainer}>
      <BalancesHead />
      <BalancesAll />
      <BalancesAccounts />
    </div>
  );
}
