import { IChartItem } from "./../types/chart";

export function getMaxChartY(
  current: IChartItem[],
  preview: IChartItem[],
  maxFactor = 1.8
) {
  const maxCurrent = Math.max(...current.map((el) => Number(el.sum_amount)));
  const maxPrevue = Math.max(...preview.map((el) => Number(el.sum_amount)));
  const maxValue = maxCurrent > maxPrevue ? maxCurrent : maxPrevue;
  const maxChartValue = maxValue * maxFactor;

  return maxChartValue;
}

export function createLineChart(data: IChartItem[], color: string) {
  return {
    data: data.map((el) => Number(el.sum_amount)),
    borderColor: color,
    backgroundColor: color,
    tension: 0.5,
    pointBorderColor: "#ffffff",
    pointBorderWidth: 2,
    pointHoverBorderWidth: 2,
  };
}

export function createBarChart(data: IChartItem[], color: string) {
  return {
    borderRadius: 5,
    data: data.map((el) => Number(el.sum_amount)),
    backgroundColor: color,
    borderColor: "#ffffff",
    borderWidth: 3,
    maxBarThickness: 10,
    barThickness: 10,
    hoverBorderColor: "#ffffff",
  };
}

let SI_SYMBOL = ["", "k", "M", "G", "T", "P", "E"];

export function abbreviateNumber(number: number) {
  // what tier? (determines SI symbol)
  let tier = (Math.log10(Math.abs(number)) / 3) | 0;

  // if zero, we don't need a suffix
  if (tier == 0) return number;

  // get suffix and determine scale
  let suffix = SI_SYMBOL[tier];
  let scale = Math.pow(10, tier * 3);

  // scale the number
  let scaled = number / scale;

  // format number and add suffix
  return scaled.toFixed(0) + suffix;
}
