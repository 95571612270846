import Button from "@/components/controls/Button/Button";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import styles from "./styles.module.scss";

export default function ModalButtonClose() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  function closeModal() {
    navigate(-1);
  }
  return (
    <div className={styles.buttonClose}>
      <Button type="primary" onClick={closeModal}>
        {t("modals.close")}
      </Button>
    </div>
  );
}
