import React, { useRef, useState } from "react";

import styles from "./styles.module.scss";
import itemStyles from "./UploaderItem/styles.module.scss";

import { ReactComponent as FileIcon } from "@assets/icons/file.svg";
import { ReactComponent as PlusIcon } from "@assets/icons/plus.svg";
import { FileDrop } from "react-file-drop";
import classNames from "classnames";
import UploaderItem from "./UploaderItem/UploaderItem";
import { useTranslation } from "react-i18next";

interface IUploader {
  files: any[];
  setFiles: React.Dispatch<React.SetStateAction<any[]>>;
}
const maxFilesCount = 2;
export default function Uploader(props: IUploader) {
  const { files, setFiles } = props;

  const [uploadActive, setUploadActive] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const { t } = useTranslation();
  const fileInputRef = useRef<null | HTMLInputElement>(null);

  function onFileDrop(fileList: FileList | null) {
    setError(null);
    if (!fileList) {
      setUploadActive(false);
      return;
    }
    let fileListArr = Array.from(fileList);
    if (
      files.length > 1 ||
      files.length + fileListArr?.length > maxFilesCount
    ) {
      setError(
        t("modals.disputeCreate.file.uploader.error") + ` - ${maxFilesCount}`
      );
      setUploadActive(false);
      return;
    }
    setFiles((cur) => cur.concat(fileListArr));
    setUploadActive(false);
  }

  const handleFileSelected = (e: React.ChangeEvent<HTMLInputElement>) => {
    setError(null);
    const fileListArr = e.target?.files && Array.from(e.target.files);
    if (!fileListArr) {
      setUploadActive(false);
      return;
    }
    if (
      files.length > 1 ||
      files.length + fileListArr?.length > maxFilesCount
    ) {
      setError(
        t("modals.disputeCreate.file.uploader.error") + `- ${maxFilesCount}`
      );
      return;
    }
    setFiles((cur) => cur.concat(fileListArr));
  };

  function removeFile(fileIndex: number) {
    setFiles((files) => files.filter((file, index) => index !== fileIndex));
  }

  return (
    <>
      <FileDrop
        onDragOver={() => setUploadActive(true)}
        onDragLeave={() => setUploadActive(false)}
        onDrop={onFileDrop}
        className={styles.uploaderContainer}
      >
        <div
          className={classNames(styles.uploader, {
            [styles.active]: uploadActive,
            [styles.muted]: files.length > 0,
          })}
        >
          {files.length === 0 ? (
            <span className={styles.uploaderText}>
              <FileIcon width={24} height={24} />
              {uploadActive
                ? t("modals.disputeCreate.file.uploader.active")
                : "PDF"}
            </span>
          ) : (
            <>
              {files.map((file, index) => (
                <UploaderItem
                  file={file}
                  key={index}
                  removeFile={() => removeFile(index)}
                />
              ))}
            </>
          )}
          {files.length < maxFilesCount && (
            <div className={itemStyles.container}>
              <div
                className={classNames(
                  files.length > 0 && styles.inputFileContainer
                )}
              >
                <input
                  type={"file"}
                  accept=".pdf"
                  multiple
                  ref={fileInputRef}
                  className={classNames(styles.inputFile, {
                    [styles.plus]: files.length > 0,
                  })}
                  onChange={handleFileSelected}
                />
                {files.length > 0 && <PlusIcon className={styles.plusIcon} />}
              </div>
              {files.length > 0 && (
                <div className={itemStyles.info}>
                  <p className={itemStyles.name}>
                    {t("modals.disputeCreate.file.uploader.add.title")}
                  </p>
                  <span className={itemStyles.otherInfo}>
                    {t("modals.disputeCreate.file.uploader.add.subTitle")}
                  </span>
                </div>
              )}
            </div>
          )}
        </div>
      </FileDrop>

      {error && <p className={styles.error}>{error}</p>}
    </>
  );
}
