import React from "react";

import styles from "./styles.module.scss";

import SubHeading from "@/components/typography/SubHeading/SubHeading";
import Card from "@/components/cards/Card/Card";
import { useTranslation } from "react-i18next";
import { ReactComponent as RuIcon } from "@assets/icons/RU.svg";
import { ReactComponent as KzIcon } from "@assets/icons/KZ.svg";
import { ReactComponent as UsIcon } from "@assets/icons/US.svg";
import { ReactComponent as TetherIcon } from "@assets/icons/tether.svg";
import { currencyColors } from "@/utils/constants/currencyColors";
import Text from "@/components/typography/Text/Text";

export default function BalancesAccounts() {
  const { t } = useTranslation();
  return (
    <>
      <div>
        <SubHeading type="default">
          {t("balancesPage.accountsProcess")}
        </SubHeading>
        <div className={styles.list}>
          <Card
            title={
              <div className={styles.accountTitle}>
                <span
                  className={styles.currency}
                  style={{ backgroundColor: currencyColors.RUB }}
                >
                  RUB
                </span>
                <span className={styles.number}>Эквайринг</span>
              </div>
            }
            titleElement={<RuIcon />}
            subTitle={"#MP002N2561663719"}
            body={"₽ 13 349 999.00"}
            bodyLeftSign={"≈"}
            footer={<Text type="accent">В процессе конвертации</Text>}
            footerElement={<span className={styles.accountState}>ВКЛ</span>}
          />
          <Card
            title={
              <div className={styles.accountTitle}>
                <span
                  className={styles.currency}
                  style={{ backgroundColor: currencyColors.USD }}
                >
                  USD
                </span>
                <span className={styles.number}>Эквайринг</span>
              </div>
            }
            titleElement={<UsIcon />}
            subTitle={"#MP002N2561663719"}
            body={"₽ 13 349 999.00"}
            bodyLeftSign={"≈"}
            footer={<Text type="accent">В процессе конвертации</Text>}
            footerElement={<span className={styles.accountState}>ВКЛ</span>}
          />
          <Card
            title={
              <div className={styles.accountTitle}>
                <span
                  className={styles.currency}
                  style={{ backgroundColor: currencyColors.USDT }}
                >
                  USDT
                </span>
                <span className={styles.number}>Эквайринг</span>
              </div>
            }
            titleElement={<TetherIcon />}
            subTitle={"#MP002N2561663719"}
            body={"₽ 13 349 999.00"}
            bodyLeftSign={"≈"}
            footer={<Text type="accent">В процессе конвертации</Text>}
            footerElement={<span className={styles.accountState}>ВКЛ</span>}
          />
          <Card
            title={
              <div className={styles.accountTitle}>
                <span
                  className={styles.currency}
                  style={{ backgroundColor: currencyColors.KZT }}
                >
                  KZT
                </span>
                <span className={styles.number}>Эквайринг</span>
              </div>
            }
            titleElement={<KzIcon />}
            subTitle={"#MP002N2561663719"}
            body={"₽ 13 349 999.00"}
            bodyLeftSign={"≈"}
            footer={<Text type="accent">В процессе конвертации</Text>}
            footerElement={<span className={styles.accountState}>ВКЛ</span>}
          />
        </div>
      </div>
      <div>
        <SubHeading type="default">
          {t("balancesPage.accountsAvailable")}
        </SubHeading>
        <div className={styles.list}>
          <Card
            title={
              <div className={styles.accountTitle}>
                <span
                  className={styles.currency}
                  style={{ backgroundColor: currencyColors.USDT }}
                >
                  USDT
                </span>
                <span className={styles.number}>P2P Рубль</span>
              </div>
            }
            titleElement={<TetherIcon />}
            subTitle={"#MP002N2561663719"}
            body={"₽ 13 349 999.00"}
            bodyLeftSign={"≈"}
            footer={<Text type="accent">Доступен для вывода</Text>}
            footerElement={<span className={styles.accountState}>ВКЛ</span>}
          />
          <Card
            title={
              <div className={styles.accountTitle}>
                <span
                  className={styles.currency}
                  style={{ backgroundColor: currencyColors.USDT }}
                >
                  USDT
                </span>
                <span className={styles.number}>Основной счет</span>
              </div>
            }
            titleElement={<TetherIcon />}
            subTitle={"#MP002N2561663719"}
            body={"₽ 13 349 999.00"}
            bodyLeftSign={"≈"}
            footer={<Text type="accent">Доступен для вывода</Text>}
            footerElement={<span className={styles.accountState}>ВКЛ</span>}
          />
          <Card
            title={
              <div className={styles.accountTitle}>
                <span
                  className={styles.currency}
                  style={{ backgroundColor: currencyColors.RUB }}
                >
                  RUB
                </span>
                <span className={styles.number}>P2P Казахстан</span>
              </div>
            }
            titleElement={<RuIcon />}
            subTitle={"#MP002N2561663719"}
            body={"₽ 13 349 999.00"}
            bodyLeftSign={"≈"}
            footer={<Text type="accent">Доступен для вывода</Text>}
            footerElement={<span className={styles.accountState}>ВКЛ</span>}
          />
          <Card
            title={
              <div className={styles.accountTitle}>
                <span
                  className={styles.currency}
                  style={{ backgroundColor: currencyColors.USD }}
                >
                  USD
                </span>
                <span className={styles.number}>Массовые выплаты RUB</span>
              </div>
            }
            titleElement={<UsIcon />}
            subTitle={"#MP002N2561663719"}
            body={"₽ 13 349 999.00"}
            bodyLeftSign={"≈"}
            footer={<Text type="accent">Доступен для вывода</Text>}
            footerElement={<span className={styles.accountState}>ВКЛ</span>}
          />
          <Card
            title={
              <div className={styles.accountTitle}>
                <span
                  className={styles.currency}
                  style={{ backgroundColor: currencyColors.USDT }}
                >
                  USDT
                </span>
                <span className={styles.number}>Массовые выплаты KZT</span>
              </div>
            }
            titleElement={<TetherIcon />}
            subTitle={"#MP002N2561663719"}
            body={"₽ 13 349 999.00"}
            bodyLeftSign={"≈"}
            footer={<Text type="accent">Доступен для вывода</Text>}
            footerElement={<span className={styles.accountState}>ВКЛ</span>}
          />
          <Card
            title={
              <div className={styles.accountTitle}>
                <span
                  className={styles.currency}
                  style={{ backgroundColor: currencyColors.KZT }}
                >
                  KZT
                </span>
                <span className={styles.number}>Массовые выплаты USD</span>
              </div>
            }
            titleElement={<KzIcon />}
            subTitle={"#MP002N2561663719"}
            body={"₽ 13 349 999.00"}
            bodyLeftSign={"≈"}
            footer={<Text type="accent">Доступен для вывода</Text>}
            footerElement={<span className={styles.accountState}>ВКЛ</span>}
          />
        </div>
      </div>
    </>
  );
}
