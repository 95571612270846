import Input from "@/components/forms/Input/Input";
import DropDown from "@/components/controls/DropDown/DropDown";
import React, { useCallback, useRef, useState } from "react";

import styles from "./styles.module.scss";

import { ReactComponent as SearchIcon } from "@assets/icons/search.svg";
import { ReactComponent as CancelIcon } from "@assets/icons/cancel.svg";
import { useTranslation } from "react-i18next";
import _ from "lodash";

interface IFilterSearch {
  onChange: (data: { value: string | null; type: string }) => void;
}

export default function FilterSearch(props: IFilterSearch) {
  const { onChange } = props;

  const [inputValue, setInputValue] = useState("");
  const [searchByType, setSearchByType] = useState("id");

  const { t } = useTranslation();
  const searchTypes = [
    {
      label: t("transactionsPage.search.dd.id"),
      value: "id",
    },
    // { label: t("transactionsPage.search.dd.client_id"), value: "id" },
    {
      label: t("transactionsPage.search.dd.external_id"),
      value: "external_id",
    },
  ];

  function onDdChange(label: string) {
    clear();
    const selected = searchTypes.find((item) => item.label === label);
    selected && setSearchByType(selected.value);
  }
  function submit() {
    onChange({ value: inputValue, type: searchByType });
  }
  function clear() {
    onChange({ value: null, type: searchByType });
    setInputValue("");
  }

  return (
    <div className={styles.container}>
      <div className={styles.left}>
        <DropDown
          mode="search"
          items={searchTypes.map((item) => item.label)}
          onChange={onDdChange}
          // placeholder={`ID ${t("transactionsPage.search.dd.placeholder")}`}
        />
      </div>
      <div className={styles.right}>
        <Input
          mode="search"
          inputAttributes={{
            value: inputValue,
            onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
              setInputValue(e.target.value),
            onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => {
              if (e.key === "Enter") {
                submit();
              }
            },
            placeholder: t("transactionsPage.search.input.placeholder"),
            // ref: inputRef,
          }}
          rightElement={
            <div className={styles.inputControls}>
              <button onClick={submit} className={styles.searchButton}>
                <SearchIcon />
              </button>
              <button onClick={clear} className={styles.cancel}>
                <CancelIcon />
              </button>
            </div>
          }
        />
      </div>
    </div>
  );
}
