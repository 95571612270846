import React from "react";

import { Outlet } from "react-router";
import Header from "./Header/Header";
import SideBar from "./SideBar/SideBar";

import styles from "./styles.module.scss";

export default function BaseLayout() {
  return (
    <div className={styles.container}>
      <SideBar />
      <div className={styles.content}>
        <Header />
        <div className={styles.body}>
          <Outlet />
        </div>
      </div>
    </div>
  );
}
