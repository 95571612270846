import React from "react";

import styles from "./styles.module.scss";

import CalculatedValue from "@/components/shared/CalculatedValue/CalculatedValue";
import { useTranslation } from "react-i18next";
import PieChart from "@/components/charts/DoughnutChart/DoughnutChart";
import { Pie } from "react-chartjs-2";

export default function BalanceGeneral() {
  const { t } = useTranslation();

  return (
    <>
      <div className={styles.subHeading}>
        <p className={styles.subHeading__text}>{t("mainPage.balancesTitle")}</p>
      </div>
      <CalculatedValue
        moneyLeft="≈"
        value="$ 5 130 682.43"
        size="lg"
        mode="money"
      />
      <p
        className={styles.description}
        dangerouslySetInnerHTML={{ __html: t("mainPage.balancesDescription") }}
      ></p>
    </>
  );
}
